import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import CallHandler from "./CallHandler";
import "font-awesome/css/font-awesome.min.css";
import * as common from "../../assets/js/helpers/common";
import bus from "../bus";
import { withTranslation } from "react-i18next";
import RtcHelpers from 'webrtc-client/src/rtc_helpers';
import "../../assets/css/call/space_call.css";

class SpaceCall extends React.Component {
    constructor(props) {
        super(props);

        this.space_id = false;
        this.call_handler_ref = React.createRef();
        this.user_status = '';
        
        this.rtc_helpers = new RtcHelpers({ socket: window.media_server_socket });

        this.state = {
            call: false,
            call_options: false,
            host_ids: [],
            is_host: false
        };

        window.space_call = this;
    }

    componentDidMount() {
        this.attachBusListeners();

        var space_id = common.getUrlParameter('space_id', true);
        var user_id = common.getUrlParameter('user_id', true);

        if(space_id && user_id) {
            this.joinSpace({ space_id, user_id });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    attachBusListeners() {
        bus.on("call__left", (data) => {
            this.onCallLeft(data);
        });

        bus.on("call__join_cancelled", () => {
            this.resetThings();
        });
    }

    async getSpace() {
        return axios.post('spaces/detail', { space_id: this.space_id })
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            return false;
        });
    }

    async joinSpace({ space_id, user_id }) {
        console.log('joinSpace', space_id);

        this.space_id = space_id;

        var space = await this.getSpace();

        console.log('space', space);

        if (!space) {
            common.showAlert({
                title: "Space not found"
            });

            return;
        }

        var room_id = `space_${space.id}`;
        var existing_room = await this.rtc_helpers.getRoom({ room_id });
        var existing_app_data = existing_room && existing_room.app_data ? existing_room.app_data : {};
        var removed_user_ids = existing_app_data.removed_user_ids || {};

        if(removed_user_ids[user_id]) {
            this.user_status = 'removed';
        }

        var call = {
            id: space.id,
            room_id,
            title: space.name,
            user_id: space.user_id,
            app_data: {
                space_id: space.id
            }
        }

        var call_options = {
            video: {
                muted: true
            },
            audio: {
                muted: true,
                can_turn_on: true,
                can_turn_off: true
            },
            breakout_rooms: false,
            enable_join_options: false,
        };

        var host_ids = [space.user_id];
        var is_host = user_id == space.user_id;

        this.setState({
            call,
            call_options,
            host_ids,
            is_host
          
        }, () => {
            this.call_handler_ref.current.joinCall({ call });
        });
    }

    onCallLeft({ soft_leave }) {
        if (soft_leave) return;

        this.resetThings();
    }

    resetThings() {
        this.space_id = false;

        this.setState({
            space: false,
            call_options: false,
            is_host: false,
            host_ids: []
        })
    }

    render() {
        return (
        <div className={'call-popup-container' + (!this.state.call ? ' d-none' : '')}>
            <CallHandler
                call={this.state.call}
                call_options={this.state.call_options}
                is_host={this.state.is_host}
                host_ids={this.state.host_ids}
                user_status={this.user_status}
                ref={ this.call_handler_ref }
            />
        </div>
        );
    }
}

export default withTranslation()(withRouter(SpaceCall));