import { Switch, Route } from "react-router-dom";
import SpaceCall from "./Call/SpaceCall";
import { useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import * as common from "../assets/js/helpers/common";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/index.css";

setupAxiosInterceptors();
connectToSocket();

function App() {
    return (
        <div className="app">
            <ToastContainer />
            
            <Switch>
                <Route path="/join" exact component={SpaceCall}></Route>
            </Switch>
        </div>
    );
}

function setupAxiosInterceptors() {
    axios.interceptors.request.use(function (config) {
        if (config.url.indexOf("https") == -1 && config.url.indexOf("http") == -1) {
            var url = config.url;

            if (url.indexOf("/") === 0) {
                url = url.substring(1);
            }
    
            config.url = process.env.REACT_APP_API_URL + "/" + url;
            config.headers.Authorization = "Bearer dummy_token";
        }
        
        return config;
    });
}

function connectToSocket() {
    var user_id = common.getUrlParameter('user_id');
    window.socket = window.media_server_socket = io(`${process.env.REACT_APP_CALL_SERVER_URL}?user_id=${user_id}`, {
        transports: ["websocket"]
    });
}

export default App;
