import React from 'react';
import bus from "../bus";
import * as datetime from "../../assets/js/helpers/datetime";
import * as common from "../../assets/js/helpers/common";
import "../../assets/css/messages/messages_listing.css";
import "../../assets/css/messages/just_messages_listing.css";

class JustMessagesListing extends React.Component {
	constructor(props) {
		super(props);

        if(common.getCurrentUser()){
			this.current_user = common.getCurrentUser();
		}else{
			this.current_user = common.getDummyUser();
		}

		this.state = {
        	messages: []
        };
	}

	componentDidMount() {
        this.attachBusListeners();
	}

    attachBusListeners() {
        bus.on('just_add_message__sent', (message) => {
            this.onNewMessage({ message });
        });
    }

    onNewMessage(params) {
        var { message } = params;

        this.setState({ 
            messages: [ ...this.state.messages, message ] 
        
        }, () => {
            this.scrollDiv.scrollIntoView({ behavior: "smooth" });
        });
    }

    render() {
        return (
            <>
            <div className='just-messages-list messages-list'>
                { this.state.messages.map((message, i) => {
                    
                    return (
                    <div className={"message" + (message.author.user_id == this.current_user.id ? " right" : " left")}>
                        <div className="message-header">
                            <div className="left-side">
                                <span className="author-name">
                                    { message.author.first_name }
                                </span>

                                <div className="time">
                                    { datetime.formatDbDate(message.created_at) }
                                </div>
                            </div>

                            <div className="right-side">
                                <img
                                    className="profile-picture"
                                    src={ message.author.profile_picture_url }
                                />
                            </div>
                        </div>

                        <div className="message-body">
                            { message.text }
                        </div>
                    </div>
                    )
                })}
            </div>

            <span className='scroll-to-bottom-dummt' ref={(el) => { this.scrollDiv = el; }}></span>

            {
                !this.state.messages.length &&

                <div className='no-messages'>
                    No messages
                </div>
            }
            </>
        )
    }
}

export default JustMessagesListing;