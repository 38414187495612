import React from 'react';
import bus from "./bus";
import $ from 'jquery';
import '../assets/css/whiteboard.css';

export default class Whiteboard extends React.Component {

	constructor(props) {
		super(props);

        this.call_library = this.props.call_library;
		this.paint_initialized = false;
		window.paint_initialized = window.paint_initialized || false;
		this.whiteboard_shared_screen = false;
		this.bus_events_ids = [];
	}

	componentDidMount() {
		this.attachBusListeners();
		this.attachEventListeners();
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};

		this.hideMe();
		this.removeBusListeners();
	}

	attachBusListeners() {
		var bus_events_ids = this.bus_events_ids;

        bus_events_ids[bus_events_ids.length] = bus.on('whiteboard__show', () => {
            this.showMe();
			
			this.whiteboard_shared_screen = true;
			bus.dispatch('call__share_current_screen' + this.props.identity);
        });
    }

	removeBusListeners() {
		this.bus_events_ids.forEach(event_id => {
			bus.remove(event_id);
		});
	  
		this.bus_events_ids = [];
	}

	attachEventListeners() {
		$('.whiteboard-modal').on('hidden.bs.modal', () => {
			
			if(this.whiteboard_shared_screen){
				this.whiteboard_shared_screen = false;
				bus.dispatch('call__stop_share_screen' + this.props.identity);
			}
		});

		$('.whiteboard-modal').on('shown.bs.modal', () => {
			if(!this.paint_initialized){
				//initializing paint
	
				var options = {
					//in second call or more, buttons icons disappears, fix for that
					path: !window.paint_initialized ? 'js/libraries/wpaint/' : '',
					menuOrientation: 'vertical',
					fillStyle: '#000'
				};

				window.jQuery_1_10_2('#whiteboard').wPaint(options);
				window.jQuery_1_10_2('#whiteboard').wPaint('resize');

				// cursor
				window.jQuery_1_10_2.extend(window.jQuery_1_10_2.fn.wPaint.cursors, {
					pencil: 'url("/plugins/main/img/cursor-pencil.png")',
				});

				this.paint_initialized = true;
				window.paint_initialized = true;
			}
		});
	}

    showMe() {
        $('.whiteboard-modal').modal('show');
    }

	hideMe() {
        $('.whiteboard-modal').modal('hide');
		bus.dispatch('whiteboard__closed');
    }

    render() {
		return (
		<div className="modal fade whiteboard-modal" role="dialog">
		    <div className="modal-dialog" role="document">
		        <div className="modal-content whiteboard-parent" id="wrapper">

					<div className="popup-close" onClick={ this.hideMe.bind(this) }>
						<i className="fas fa-times"></i>
					</div>

					<div id="whiteboard"></div>

				</div>
			</div>
		</div> )
	}
}