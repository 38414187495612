import React from 'react';
import bus from "../bus";
import JustMessagesListing from "../Messages/JustMessagesListing";
import JustAddMessage from "../Messages/JustAddMessage";
import MessagesListing from "../Messages/MessagesListing";
import AddMessage from "../Messages/AddMessage";
import  { withTranslation }  from 'react-i18next';
import i18next from "i18next";
import "../../assets/css/call/call_chat.css";

class CallChat extends React.Component {
	constructor(props) {
		super(props);

        this.just_messages_listing_ref = React.createRef();

        this.state = {
            show_me: false
        }

        this.attachBusListeners();
        this.attachSocketListeners();
	}

    attachBusListeners() {
        bus.on('side_panel__hide_all', () => {
            this.hideMe();
        });

        bus.on('chat__toggle', () => {
            if(this.state.show_me) {
                this.hideMe();
                return;
            }

            this.showMe();
        });
    }

    attachSocketListeners() {
        window.rtc_client.socket.on("breakout-room-message", (params) => {
            this.onBreakoutRoomMessage(params);
        });
    }

    onBreakoutRoomMessage(params) {
        var { message } = params;
    
        this.just_messages_listing_ref.current.onNewMessage({ message });
    }

    sendBreakoutRoomMessage(params) {
        var { message } = params;
    
        window.rtc_client.notifyOtherUsers({
            event: "breakout-room-message",
            data: {
                message
            }
        });
    }

    showMe() {
        bus.dispatch('side_panel__hide_all');

        bus.dispatch(
            'side_panel__visibility_changed', 
            { is_shown: 1, section_name: 'chat'}
        );

        this.setState({ show_me: true });
    }

    hideMe() {
        bus.dispatch(
            'side_panel__visibility_changed', 
            { is_shown: 0, section_name: 'chat'}
        );

        this.setState({ show_me: false });
    }

    render() {
        return (
        <div className={"side-panel chat-section" + (this.state.show_me ? '' : ' d-none')}> 
            
            <div className='side-panel-header'>
                <span>{ i18next.t("Chat") }</span>

                <div className='actions'>
                    {/* <i class="fas fa-angle-down action"></i> */}
                    <i class="fas fa-times action" onClick={ () => this.hideMe() }></i>
                </div>
            </div>
            
            <div className='side-panel-body'>
                { !this.props.breakout_room 
                    
                    ? (
                    <>
                    <MessagesListing
                        id={this.props.record_id}
                    />
                
                    <AddMessage
                        host={this.props.is_host}
                        chat={this.props.chat}
                        id={this.props.record_id}
                        current_user={this.props.current_user}
                        participants={this.props.participants}
                        participants_ids={this.props.participants_ids}
                    />
                    </>
                    
                    ) : (
                    <>
                    <JustMessagesListing
                        ref={this.just_messages_listing_ref}
                    />
                    {/* Yoonus added to check if chat enabled or disabled */}
                    <JustAddMessage   chat={this.props.chat}
                        sendMessage={this.sendBreakoutRoomMessage.bind(this)}
                    />
                    </>
                )}
            </div>
        </div>
        )
    }
}

export default withTranslation()(CallChat);