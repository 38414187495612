import React from 'react';
import bus from "../bus";
import * as common from "../../assets/js/helpers/common";
import * as datetime from "../../assets/js/helpers/datetime";
import "../../assets/css/messages/add_message.css";
import "../../assets/css/messages/just_add_message.css";

class JustAddMessage extends React.Component {
	constructor(props) {
		super(props);

        if(common.getCurrentUser()){
			this.current_user = common.getCurrentUser();
		}else{
			this.current_user = common.getDummyUser();
		}

		this.state = {
        	message: {},
            chat: props.chat == "off"?true:false,
        };
        
	}
    //Yoonus added to update  chat according to condition
    componentDidUpdate(prev_props) {
        if (prev_props.chat != this.props.chat) {
            if (prev_props.chat == "off") {
                this.setState({chat:true});
            } else if (prev_props.host == false && prev_props.chat == "host_only") {
                this.setState({chat:true});
            } else if (prev_props.chat == "on") {
                this.setState({chat:false});
            }
          }
      }

    onTextChanged(event) {
        if(!event.target.value) return;

        this.setState({ 
            message: { 
                text: event.target.value,
                author: this.current_user,
                created_at: datetime.getUtcDate()
            } 
        });
    }

    sendMessage() {
        if(!this.state.message.text) return;

        this.props.sendMessage({ message: this.state.message });

        bus.dispatch('just_add_message__sent', this.state.message);

        this.setState({ 
            message: { 
                text: ''
            }
        });
    }

    render() {
        return (
            <div className="just-add-message add-message">
                <input 
                    type='text' 
                    id='input-field'
                    disabled={this.state.chat}
                    placeholder='Type message'
                    value={ this.state.message.text }
                    onChange={ this.onTextChanged.bind(this) }
                    onKeyDown={
                        (e) => { 
                            if(e.key == 'Enter'){                    
                                this.sendMessage();
                            }
                        }
                    }
                    />

                <button 
                    className='button send-btn' 
                    onClick={ this.sendMessage.bind(this) }>
                    
                    <i class="fas fa-paper-plane"></i>
                </button>
            </div>
        );
    }
}

export default JustAddMessage;