import RtcClient from "../rtc_client";
import SfuRtcConnection from "./sfu_rtc_connection";
import * as mediasoup_client from "mediasoup-client"; 

class SfuRtcClient extends RtcClient {
    constructor(params) {
        super(params);

        this.room_type = 'mediasoup-sfu';
        this.device = false;
        this.producers = {};
        this.connections_wrt_user = {};

        this.user_encodings = [
            {
              maxBitrate: 100000,
              scalabilityMode: 'S1T3',
            },
            {
              maxBitrate: 500000,
              scalabilityMode: 'S1T3',
            }
        ];

        this.screen_encodings = [
            {
              maxBitrate: 1000000
            },
        ];
        
        this.codec_options = {
            videoGoogleStartBitrate: 1000
        }
    }

    attachSocketListeners() {
        super.attachSocketListeners();

        this.socket.on('room:consumer-closed', (params) => {
            this.onServerConsumerClosed(params);
        });

        this.socket.on('room:producer-closed', (params) => {
            this.onServerProducerClosed(params);
        });

        this.socket.on('room:transport-closed', (params) => {
            this.onServerTransportClosed(params);
        });

        this.socket.on('room:new-producer', (params) => {
            this.onNewProducer(params);
        });

        this.socket.on('room:get-producer', (params) => {
            this.onGetProducer(params);
        });
    }

    _onConnectionReconnecting(params) {
        var { connection } = params;

        var user_id = connection.user_id;
        var type = connection.type;

        if(type != 'producer') return;

        Object.values(this.connections).forEach(connection => {

            if(connection.user_id != user_id || connection.type != 'consumer') return;

            connection.onOtherEndReconnecting(params);
        });
    }

    _onConnectionReconnected(params) {
        var { connection } = params;

        var user_id = connection.user_id;
        var type = connection.type;

        if(type != 'producer') return;

        Object.values(this.connections).forEach(connection => {

            if(connection.user_id != user_id || connection.type != 'consumer') return;

            connection.onOtherEndReconnected(params);
        });
    }

    async getPostJoinData() {
        var { available_producers } = await super.getPostJoinData();

        Object.values(available_producers).forEach(producer => {
            this.onNewProducer({ producer, user_id: producer.user_id });
        });

        this.producers = available_producers;
    }

    async turnOnVideo(params) {
        await super.turnOnVideo(params);

        this.addTrack({ 
            track: this.stream.getVideoTracks()[0], 
            track_type: 'user',
            stream: this.stream
        });
    }

    async turnOnAudio(params) {
        await super.turnOnAudio(params);

        this.addTrack({ 
            track: this.stream.getAudioTracks()[0], 
            track_type: 'user',
            stream: this.stream
        });
    }

    turnOffAudio() {
        if(!this.stream) return;

        this.removeTrack({ 
            track_type: 'user',
            track_kind: 'audio'
        });

        super.turnOffAudio();
    }

    pauseAudio() {
        if(!super.pauseAudio()) return;

        var connection = this.getProducerConnection({ track_kind: 'audio' });
        connection && connection.pauseProducer();
    }

    resumeAudio() {
        if(!super.resumeAudio()) return;

        var connection = this.getProducerConnection({ track_kind: 'audio' });
        connection && connection.resumeProducer();
    }

    switchAudioSource() {
        var new_track = !super.switchAudioSource();
        if(!new_track) return;

        var connection = this.getProducerConnection({ track_kind: 'audio' });
        connection && connection.replaceTrack({ track: new_track });
    }

    shareScreenStream(params) {
        var { stream } = params;

        super.shareScreenStream(params);

        this.addTrack({ 
            track: stream.getVideoTracks()[0], 
            track_type: 'screen',
            stream
        });
    }

    stopShareScreen() {
        if(!super.stopShareScreen()) return;

        this.removeTrack({ 
            track_type: 'screen',
            track_kind: 'video'
        });
    }

    getUserVideoStream(params) {
        params.track_kind = 'video'

        return this.getUserTrack(params);
    }

    getUserAudioStream(params) {
        params.track_kind = 'audio'

        return this.getUserTrack(params);
    }

    dropUserVideoStream(params) {
        var { user_id } = params;
        
        this.dropTrack({ 
            user_id, 
            track_type: 'user', 
            track_kind: 'video' 
        });
    }

    dropUserAudioStream(params) {
        var { user_id } = params;
        
        this.dropTrack({ 
            user_id, 
            track_type: 'user', 
            track_kind: 'audio' 
        });
    }

    getUserStream(params) {
        var { user_id } = params;

        this.getUserTrack({ user_id, track_kind: 'video' });
        this.getUserTrack({ user_id, track_kind: 'audio' });
    }

    async getUserTrack(params) {
        var { user_id, track_kind } = params;

        params.track_type = 'user';

        var is_me = (user_id == this.user.id);
        var participant = this.participants[user_id];
        
        console.log(
            'getUserTrack', 
            'user_id', user_id,
            'is_me', is_me,
            'track_kind', track_kind, 
            'participant', participant
        );

        if(!participant) return;

        if(is_me) {
            this.onRemoteStream({ 
                stream: this.stream, 
                stream_type: 'user', 
                user_id 
            });
            
            return;
        }

        return this.getTrack(params);
    }

    getScreenStream(params) {
        var { user_id } = params;

        return this.getTrack({ 
            user_id, 
            track_type: 'screen', 
            track_kind: 'video' 
        });
    }

    dropScreenStream(params) {
        var { user_id } = params;
        
        this.dropTrack({ 
            user_id, 
            track_type: 'screen', 
            track_kind: 'video' 
        });
    }

    dropUserStream(params) {
        var { user_id } = params;

        this.dropUserVideoStream({ user_id });
        this.dropUserAudioStream({ user_id });
    }

    replaceTrack(params) {
        var { track } = params;

        var track_kind = track.kind;
        var track_type = track.track_info.type;

        var connection = this.getProducerConnection({ track_kind, track_type });
        connection && connection.replaceTrack(params);
    }

    async initiateConnection(params) {
        var { router } = params;

        this.device = new mediasoup_client.Device();
        await this.device.load({ routerRtpCapabilities: router.rtp_capabilities });

        await super.initiateConnection();

        if(this.stream){
            this.addStream({ 
                stream: this.stream, 
                stream_type: 'user' 
            });
        }

        this.joined();
    }

    addStream({ stream, stream_type }) {
        stream.getTracks().forEach(track => {
            this.addTrack({ track, track_type: stream_type, stream });
        });
    }

    addTrack({ track, track_type, stream }) {
        var producer_name = SfuRtcConnection.makeProducerName({ user_id: this.user.id, track_type, track_kind: track.kind });
        var connection = this.getOrMakeConnection({ 
            id: producer_name,
            type: 'producer', 
            user: this.user,
            producer_name,
            track_type, 
            track_kind: track.kind
        });
                
        connection.addTrack({
            track,
            track_type,
            stream
        });
    }

    removeTrack({ track_type, track_kind }) {
        var connection = this.getProducerConnection({ track_type, track_kind });
        connection && connection.close();
    }

    getTrack({ user_id, track_type, track_kind }) {
        var producer_name = SfuRtcConnection.makeProducerName({ user_id, track_type, track_kind });
        var producer_available = this.producers[producer_name];
        
        console.log(
            'ttttt getTrack',
            'track_type', track_type, 
            'track_kind', track_kind,
            'producer_name', producer_name,
            'producer_available', producer_available
        );

        if(!producer_available) return;

        var consumer_name = SfuRtcConnection.makeConsumerName({ user_id, track_type, track_kind });
        var connection = this.getOrMakeConnection({
            id: consumer_name,
            type: 'consumer', 
            user: this.participants[user_id],
            producer_name,
            track_type, 
            track_kind
        });
                
        connection && connection.getTrack({
            user_id, 
            track_type, 
            track_kind
        });
    }

    dropTrack({ user_id, track_type, track_kind }) {
        var connection = this.getConsumerConnection({ user_id, track_type, track_kind });
        connection && connection.close();
    }

    getOrMakeConnection(params) {
        var { id, user, type } = params;

        console.log(
            'ttttt getOrMakeConnection',
            'id', id,
            'type', type,
            'existing_connection', this.connections[id],
            'params', params
        );

        if(this.connections[id]) return this.connections[id];

        params.rtc_client = this;
        
        var connection = new SfuRtcConnection(params);

        this.connections[connection.id] = connection;
        this.connections_wrt_user[user.id] = this.connections_wrt_user[user.id] || [];
        this.connections_wrt_user[user.id].push(connection.id);

        return connection;
    }

    onServerConsumerClosed(params) {
        Object.values(this.connections).forEach((connection) => {
            connection.onServerConsumerClosed(params);
        });
    }

    onServerTransportClosed(params) {
        Object.values(this.connections).forEach((connection) => {
            connection.onServerTransportClosed(params);
        });
    }

    onServerProducerClosed(params) {
        var { producer_name } = params;
        
        delete this.producers[producer_name];
    }

    onNewProducer(params) {
        var { producer, user_id } = params;

        console.log('sfu_rtc_client onNewProducer', producer);

        this.producers[producer.name] = producer;
        var track_kind = producer.track_kind.charAt(0).toUpperCase() + producer.track_kind.slice(1);
        var track_type = producer.track_type.charAt(0).toUpperCase() + producer.track_type.slice(1);

        Object.values(this.connections).forEach((connection) => {
            if(connection.user_id != user_id) return;

            connection.onNewProducer(params);
        });

        this.callCallback({
            callback: this[`onPotential${track_type}${track_kind}`], 
            data: { user_id }
        });
    }

    onGetProducer(params) {
        var { producer_name, user_id, socket_response_event } = params;

        var producer = false;
        var connection = this.connections[producer_name];

        console.log('onGetProducer', connection);

        if(connection && !connection.is_reconnecting) {
            var connection_producer = connection.producer;
            
            producer = { 
                id: connection_producer.id,
                name: connection_producer.producer_name,
                transport_id: connection.transport.id
            }
        }

        this.socketEmit({
            data: {
                producer,
                to_user_id: user_id,
                socket_response_event
            }
        });
    }

    _onJoined(params) {
        var { user, available_producers } = params;

        if(user.id == this.user.id) {
            this.producers = available_producers;
        }

        super._onJoined(params);
    }

    // close connection
    closeConnection(params) {
        var { user_id } = params;

        var connection_ids = this.connections_wrt_user[user_id];
        if(!connection_ids) return;

        connection_ids.forEach(connection_id => {

            this.connections[connection_id] && this.connections[connection_id].close();
            delete this.connections[connection_id];
        });
        
        delete this.connections_wrt_user[user_id];
    }

    getProducerConnection({ track_type = 'user', track_kind }) {
        var connection_id = SfuRtcConnection.makeProducerName({
            user_id: this.user.id,
            track_type,
            track_kind
        });

        console.log('tttttt getProducerConnection', connection_id, this.connections[connection_id]);

        return this.connections[connection_id];
    }

    getConsumerConnection({ user_id, track_type = 'user', track_kind }) {
        var connection_id = SfuRtcConnection.makeConsumerName({
            user_id,
            track_type,
            track_kind
        });

        console.log('tttttt getConsumerConnection', connection_id, this.connections[connection_id]);

        return this.connections[connection_id];
    }
}

export default SfuRtcClient