import React, { useRef, useEffect, useState } from "react";
import "../style.css";
import { useSelector } from "react-redux";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";
import bus from "../bus";
import document from "../../assets/images/document.png";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import * as common from "../../assets/js/helpers/common";
import "../../assets/css/messages/messages_listing.css";
import Grid from '@mui/material/Grid';
import video_icon from '../../assets/images/message/video_icon.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const MessagesList = (props) => {
  var get_user = useSelector((state) => state.auth);
  const dummy_user = common.getDummyUser() ? common.getDummyUser() : "";
  const user = common.getCurrentUser() ? get_user : "";
  let [messages, setMessages] = useState([]);
  const numRows = messages.length;
  const classes = useStyles();
  const messagesEndRef = useRef(null);
  var older_than_id = "";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    attachSocketListeners();
    attachBusListeners();
    loadMessages();

    // Anything in here is fired on component unmount.
    return () => {
      removeSocketListeners();
    };
  }, []);

  const loadMessages = () => {
    if (common.getCurrentUser()) {
      axios
        .get("messages/get_messages", {
          params: {
            limit: 50,
            meeting_id: props.id,
            older_than_id,
          },
        })
        .then((responce) => {
          if (responce.data.data.length) {
            setMessages((messages) => [...messages, ...responce.data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get("messages/get_guest_messages", {
          params: {
            limit: 50,
            meeting_id: props.id,
          },
        })
        .then((responce) => {
          if (responce.data.data.length) {
            setMessages((messages) => [...messages, ...responce.data.data]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const attachBusListeners = () => {
    bus.on("add_message__message_sent", function (message) {
      setMessages((messages) => [...messages, message]);
    });
  };

  const attachSocketListeners = () => {
    window.socket.on("new_message_meeting_" + props.id, function (message) {
      setMessages((messages) => [...messages, message.message]);
    });
  };

  const removeSocketListeners = () => {
    window.socket.removeAllListeners("new_message_meeting_" + props.id);
  };

  const scrollToBottom = () => {
    if (numRows > 0) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = (e) => {
    let element = e.target;
    older_than_id = messages[0].id;

    if (element.scrollTop === 0) {
      loadMessages();
    }
  };

  const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" class="right_side">' +
        url +
        "</a>"
      );
    });
  };

  useEffect(scrollToBottom, [messages]);

  messages.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

  //Yoonus added to show big names
  const showNameWithEllipsis = (text,con,user)=>{
    if(con?.to_id == user?.user_id)
    return text.length>8 ?text.substring(0, 8) + " ...":text;
    else if(!!(
      con?.to_id > 0 &&
      con?.to_id != user.user_id &&
      con?.user_id == user.user_id
    ))
    return text.length>8 ?text.substring(0, 8) + " ...":text;
    else if(text && text.length>14)
    return text.substring(0, 12) + " ...";
    else return text
  }

  return (
    <>
      <div className="messages-list" onScroll={handleScroll}>
        <>
          {messages.map((con, i) => {
            console.log(con);
            const momentDate = moment.utc(con.date).local();

								<div className="private-info">
									You received private message from { con.user_id ? con.author.first_name : con.user_name }
								</div>
							
            const format = momentDate.format("hh:mm A, MMM D, YYYY");
            return (
              <div
                className={
                  "message" + (con.user_id == user.user_id ? " right" : " left")
                }
                key={con.id}
              >
                  <div className="message-header">
                    {
                      //Yoonus changed to show the header of chat sender name,time,date to get aligned
                      (con.user_id == user.user_id) ?


                        <Grid container spacing={1} style={{  justifyContent: "flex-start" ,alignItems:"center" }}>
                          <Grid item xs={6}>
                            <div className={"author-name marginHostUserName"}>
                              {con.user_id ? showNameWithEllipsis(con.author.first_name,con,user) : showNameWithEllipsis(con.user_name,con,user)}
                              {con.to_id == user.user_id && (
                                <span className="privateText">
                                  (Private)
                                </span>
                              )}
                              {!!(
                                con.to_id > 0 &&
                                con.to_id != user.user_id &&
                                con.user_id == user.user_id
                              ) && (

                                  <span className="privateText">
                                    (Private)
                                  </span>

                                )}
                            </div>
                          </Grid>
                          {/* <Grid item xs={1}></Grid> */}
                          <Grid item xs={4}>
                            <div className="time" >{format}</div>
                          </Grid>
                          <Grid item xs={2}>
                            <div><img
                              className="profile-picture"
                              src={con.user_id ? con.author.profile_picture_url : 'https://mizdah.com/images/system/default_user_picture.jpg'} /></div>
                          </Grid>


                        </Grid>

                        : <Grid container spacing={1} style={{ alignItems: "center" }}>
                          <Grid item xs={2}>
                            <img
                              className="profile-picture"
                              src={con.user_id ? con.author.profile_picture_url : 'https://mizdah.com/images/system/default_user_picture.jpg'}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <span className="author-name participantNameMargin">
                              {con.user_id ? showNameWithEllipsis(con.author.first_name,con,user) : showNameWithEllipsis(con.user_name,con,user)}
                              {con.to_id == user.user_id && (

                                <span className="privateText">
                                  (Private)
                                </span>

                              )}
                              {!!(
                                con.to_id > 0 &&
                                con.to_id != user.user_id &&
                                con.user_id == user.user_id
                              ) && (

                                  <span className="privateText">
                                    (Private)
                                  </span>

                                )}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="time " >{format}</div>
                          </Grid>

                        </Grid>
                    }

                    <div className="clearfix"></div>
                  </div>

                <div className={(con.user_id == user.user_id)? "message-body": "message-body message-body-margin"} >
                  {con.text != null && (
                    <span className="chatFontSize">{ReactHtmlParser(urlify(con.text))}</span>
                  )}

                  {con.file_type == "image" && (
                    <a
                      className="customHidden"
                      id={`anchor-id-${i}`}
                      href={con.url}
                      target="_blank"
                      download
                    >
                      <img src={con.url} className="imgsize" />
                      <span className="image-Icon">
                        <Avatar
                          style={{ backgroundColor: "transparent" }}
                          className={classes.small}
                        >
                          <ArrowDownwardIcon style={{ fontSize: "20px" }} />
                        </Avatar>
                      </span>
                    </a>
                  )}

                  {con.image_type && (
                    <a
                      className="customHidden"
                      id={`anchor-id-${i}`}
                      href={con.url}
                      target="_blank"
                      download
                    >
                      <img src={con.url} className="imgsize" />
                      <span className="image-Icon">
                        <Avatar
                          style={{ backgroundColor: "transparent" }}
                          className={classes.small}
                        >
                          <ArrowDownwardIcon style={{ fontSize: "20px" }} />
                        </Avatar>
                      </span>
                    </a>
                  )}

                  {con.audio_type && (
                    <a href={con.url} target="_blank" download>
                      <img src={con.url} className="imgsize" />
                      <span className="image-Icon">
                        <Avatar
                          style={{ backgroundColor: "transparent" }}
                          className={classes.small}
                        >
                          <ArrowDownwardIcon style={{ fontSize: "20px" }} />
                        </Avatar>
                      </span>
                    </a>
                  )}

                  {con.file_type == "video" &&
                    con.url.split(".").pop() != "mkv" && (
                      <a
                        className="customHidden"
                        id={`anchor-id-${i}`}
                        href={con.url}
                        target="_blank"
                        download
                      >
                        <img src={video_icon} className="imgsize" />                       
                         <span className="image-Icon">
                          <Avatar
                            style={{ backgroundColor: "transparent" }}
                            className={classes.small}
                          >
                            <ArrowDownwardIcon style={{ fontSize: "20px" }} />
                          </Avatar>
                        </span>
                      </a>
                    )}

                  {con.text == null &&
                    con.file_type != "image" &&
                    !con.image_type &&
                    !con.audio_type &&
                    !(
                      con.file_type == "video" &&
                      con.url.split(".").pop() != "mkv"
                    ) && (
                      <a
                        className="customHidden"
                        id={`anchor-id-${i}`}
                        href={con.url}
                        target="_blank"
                        download
                      >
                        <img src={document}  className="imgsize" />
                        <span className="image-Icon">
                          <Avatar
                            style={{ backgroundColor: "transparent" }}
                            className={classes.small}
                          >
                            <ArrowDownwardIcon style={{ fontSize: "20px" }} />
                          </Avatar>
                        </span>
                      </a>
                    )}
                </div>
              </div>
            );
          })}

          <span ref={messagesEndRef} />
        </>
      </div>

      {!numRows && <span className="no-messages"> {t("ChatNotFound")}</span>}
    </>
  );
};

export default MessagesList;
