import React from "react";
import axios from "axios";
import $ from "jquery";
import bus from "../bus";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/call/call.css";
import * as common from "../../assets/js/helpers/common";
import * as datetime from "../../assets/js/helpers/datetime";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import DeviceDetector from "device-detector-js";
import i18next from "i18next";
import CallLibrary from "./CallLibrary";
import ActionsComponent from "./CallActions";
import CallAuth from "./CallAuth";
import CallTimeMaster from "./CallTimeMaster";
import AddToCall from "./AddToCall";
import CallParticipantsSection from "./CallParticipantsSection";
import ShareScreenPicker from "./ShareScreenPicker";
import Whiteboard from "../Whiteboard";
import CallRaisedHands from "./CallRaisedHands";
import CallRecorder from "./CallRecorder";
import CallSharedScreen from "./CallSharedScreen";
import CallMaximizedStream from "./CallMaximizedStream";
import JoinOptions from "./JoinOptions";
import CallBreakoutRooms from "./CallBreakoutRooms";
import VideoEffectsComponent from "./CallVideoEffects";
import AddTimeToCall from "./AddTimeToCall";
import PermissionAllowPopup from "./PermissionAllowPopup"
import VideoManagerComponent from "./VideoManager";
import AudioManager from "./AudioManager";
import CallChat from "./CallChat";
import CallReactions from "./CallReactions";
import MeetingJoinedSound from "../../assets/audios/meeting_joined.mp3"
import GoogleAd from "components/GoogleAd";
import CallSpeakRequests from "./CallSpeakRequests";

var VideoManager;
var CallActions;
var VideoEffects;

export default class Call extends React.Component {
  constructor(props) {
    super(props);
    this.isMeetNow = this.props.call.meet_now == 1 ? true : false;
    this.record_id = this.props.call.id;
    if (this.props.call.type == "private")
      this.record_id = this.props.call.other_user.id;

    if (common.getCurrentUser()) {
      this.current_user = common.getCurrentUser();
    } else {
      this.current_user = common.getDummyUser();
    }

    this.call = this.props.call;
    this.call_library = null;
    this.current_user.name = this.current_user.first_name + ' ' + this.current_user.last_name;
    this.current_user.profile_picture = this.current_user.profile_picture_url;
    this.user_status = "";

    this.call.cache_data_key = this.call.room_id + "_cache_data";
    this.local_logs = "";

    //to make bus events unique
    //otherwise they will get called n number of times
    //where n is number of times call component mounted
    this.identity = Math.random();

    this.call_breakout_rooms_ref = React.createRef();
    this.video_effects_ref = window.video_effects_ref = React.createRef();
    this.actions_ref = React.createRef();
    this.shared_screen_ref = React.createRef();
    this.audio_ref = React.createRef();

    this.call_settings = localStorage.getItem("call_settings");
    this.call_settings = this.call_settings
      ? JSON.parse(this.call_settings)
      : {};
    this.breakout_room = this.is_breakout_room = this.props.breakout_room;
    this.breakout_room_to_join = false;
    this.have_left_call = false;

    this.call_title = this.props.call.title || "Meeting";
    if (this.breakout_room) {
      this.call_title = `Breakout Room: ${this.breakout_room.title} (${this.call_title})`;
    }

    //default call options
    this.default_call_options = {
      auth: {
        //call join password
        password: "",
        is_approval_needed: false
      },
      leave: {
        //ask user about ending the call etc
        show_options: false,
      },
      timing: {
        //call start time, datetime
        start_time: "",
        //call duration minutes
        duration_minutes: "",
        //call time timezone
        time_zone: "",
      },
      video: {
        muted: 0,
        can_turn_on: true
      },
      audio: {
        muted: 0,
        can_turn_on: true,
        can_turn_off: true,
      },
      share_screen: {
        enabled: true,
      },
      chat: {
        //send message is allowed or not
        send_msg: true,
      },
      participants: {
        max_participants: false,
      },
      urls: {
        //to trigger when call is started
        call_started: "",
        //to trigger when user has joined the call
        user_joined: "",
        //to trigger when join request is made
        join_request: "",
        //to get join requests for the call
        join_requests: "",
        //to update join request status
        update_join_request: "",
      },
      socket_events: {}
    };

    this.state = {
      call: this.props.call,
      call_joined: false,
      current_user: this.current_user,
      call_options: {
        ...this.default_call_options,
        ...this.props.call_options,
      },
      participants: [],
      participants_ids: [],
      screen_sharing_users: [],
      recording_users: [],
      video_on_users: [],
      audio_on_users: [],
      is_host: false,
      join_options_passed: !this.props.call_options.enable_join_options,
      is_video_on: false,
      is_audio_on: false,
      host_ids: this.props.host_ids || [],
      is_reconnecting: false,
      sharing_screen: false,
      slow_network: false,
      slow_network_start: false,
      video_paused: false,
      init_library: true,
      mix_audio_stream: false,
      view_type: "gallery",
      video_effect_type: "",
      permission_check: false,
      is_full_screen: false,
      is_countdown_started: false,
      time_check_passed: false,
      show_actions: true
    };

    this.state.is_host = this.props.host_ids.includes(this.current_user.id);

    this.joined_sound = new Audio(MeetingJoinedSound);
    this.joined_sound.volume = '0.2';
  }

  componentDidMount() {
    this.prepareThings();
    this.attachBusListeners();
    this.keepScreenAwake();

    window.call_component = this;

    window.localStorage.setItem(
      "debug",
      "* -engine* -socket* -RIE* *WARN* *ERROR*"
    );

    bus.dispatch("call__mounted");
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };

    window.call = false;
    window.connection = false;
    window.rtc_client = false;
    window.call_component = false;

    this.keepScreenAwake(false);
    // this.removeSocketListeners();
    this.downloadLocalLogs();

    clearInterval(this.network_interval_id);
  }

  prepareThings() {
    var device_detector = new DeviceDetector();
    this.device = device_detector.parse(window.navigator.userAgent);
    this.subscription = common.getSubscription();
    
    this.is_basic_plan = false;
    if(this.subscription) {
      this.is_basic_plan = (this.subscription.plan_id == 1);
    }

    VideoManager = this.props.VideoManagerComponent || VideoManagerComponent;
    CallActions = this.props.ActionsComponent || ActionsComponent;
    VideoEffects = this.props.VideoEffectsComponent || VideoEffectsComponent;

    if(!this.props.call_options.enable_join_options) {
      bus.dispatch('call__going_to_join');
    }
  }

  attachBusListeners() {
    bus.on("call_time_master__allowed" + this.identity, () => {
      this.setState({ time_check_passed: true });
    });

    bus.on("call_auth__authenticated" + this.identity, () => {
      this.setState((prevState) => ({
        current_user: {
          ...prevState.current_user,
          is_authenticated: true,
        },
      }));
    });

    bus.on("call__updated_participants", (participants) => {
      this.setState({ participants: participants });
    });

    //time finished
    bus.on("call_time_master__time_ended", () => {
      window.leaveCall({ reason: "time ended" });
    });

    bus.on("call_time_master__visbility_changed", (is_shown) => {
      this.setState({ is_countdown_started: is_shown });
    });

    bus.on("call_actions__left", () => {
      this.haveLeftCall();
    });

    bus.on("call_actions__ended", () => {
      this.breakout_room = false;
      this.haveLeftCall();
    });

    bus.on("call__log_event_to_local", (data) => {
      this.logEventToLocal(data);
    });

    bus.on("call_video_effects__effect_changed", ({ effect_type }) => {
      this.setState({ video_effect_type: effect_type });
    });

    bus.on("side_panel__visibility_changed", ({ is_shown }) => {
      this.setState({ side_panel_opened: is_shown });
    });
  }

  attachSocketListeners() {
    window.socket.on(
      "meeting_user_host__added" + this.props.call.id + this.props.call.type,
      (data) => {
        if (this.current_user.id == data.host_id) {
          this.setState({ is_host: true });
          toast.success(i18next.t("AddedAsHost"), {
            position: "bottom-left",
            className: "call-toast",
          });
        }

        this.addToHostIds(data.host_id);
      }
    );

    window.socket.on(
      "meeting_user_host__removed" + this.props.call.id + this.props.call.type,
      (data) => {
        if (this.current_user.id == data.host_id) {
          this.setState({ is_host: false });
          toast.success(i18next.t("youAreRemovedAsHost"), {
            position: "bottom-left",
            className: "call-toast",
          });
        }
        this.removeFromHostIds(data.host_id);
      }
    );

    this.rtc_client.socket.on("join-breakout-room", (params) => {
      this.joinBreakoutRoom(params);
    });
  }

  removeSocketListeners() {
    window.socket.off(
      "meeting_user_host__added" + this.props.call.id + this.props.call.type
    );
    window.socket.off(
      "meeting_user_host__removed" + this.props.call.id + this.props.call.type
    );
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  callLibraryInitiated(call_library) {
    this.call_library = call_library;
    this.rtc_client = this.call_library.rtc_client;

    this.rtc_client.onScreenStream = this.onScreenStream.bind(this);
    this.rtc_client.onLocalUserVideoStream = this.onLocalUserVideoStream.bind(this);
    this.rtc_client.onLocalUserAudioStream = this.onLocalUserAudioStream.bind(this);
    this.rtc_client.onScreenStreamEnded = this.onScreenStreamEnded.bind(this);
    this.rtc_client.onVideoError = this.onVideoError.bind(this);
    this.rtc_client.onAudioError = this.onAudioError.bind(this);
    this.rtc_client.onPostJoinData = this.onPostJoinData.bind(this);
    this.rtc_client.onShareScreenError = this.onShareScreenError.bind(this);
    this.rtc_client.onJoined = this.onJoined.bind(this);
    this.rtc_client.onLeft = this.onLeft.bind(this);
    this.rtc_client.onCallStarted = this.onCallStarted.bind(this);
    this.rtc_client.onCallEnded = this.onCallEnded.bind(this);
    this.rtc_client.onVideoSwitched = this.onVideoSwitched.bind(this);
    this.rtc_client.onAudioSwitched = this.onAudioSwitched.bind(this);
    this.rtc_client.onScreenShared = this.onScreenShared.bind(this);
    this.rtc_client.onShareScreenStopped = this.onShareScreenStopped.bind(this);
    this.rtc_client.onPotentialScreenVideo = this.onPotentialScreenVideo.bind(this);
    this.rtc_client.onDisconnected = this.onDisconnected.bind(this);
    this.rtc_client.onReconnecting = this.onReconnecting.bind(this);
    this.rtc_client.onReconnected = this.onReconnected.bind(this);
    this.rtc_client.onReconnectionFailed = this.onReconnectionFailed.bind(this);
    this.rtc_client.onRemoved = this.onRemoved.bind(this);
    this.rtc_client.onRecordingStarted = this.onRecordingStarted.bind(this);
    this.rtc_client.onRecordingStopped = this.onRecordingStopped.bind(this);
    this.rtc_client.onUsersAdded = this.onUsersAdded.bind(this);
    this.rtc_client.onSocketSent = this.onSocketSent.bind(this);
    this.rtc_client.onSocketReceived = this.onSocketReceived.bind(this);
    this.rtc_client.onSocketConnected = this.onSocketConnected.bind(this);
    this.rtc_client.onSocketDisconnected = this.onSocketDisconnected.bind(this);
    this.rtc_client.onLeaveCall = this.onLeaveCall.bind(this);
    this.rtc_client.onCallback = this.onCallback.bind(this);

    // this.attachSocketListeners();

    bus.dispatch("call__rtc_client_ready", this.rtc_client);

    window.rtc_client = this.rtc_client;
    window.call = this.state.call;
  }

  onScreenStream() {
    this.setState({ viewing_screen: true });
    this.logEventToServer("screen_stream");
  }

  onLocalUserAudioStream({ track }) {
    toast.info(() => {
      return (
        <div className="toast-inner">
          <i className="fa fa-microphone icon"></i>
          { i18next.t("Connected to") }: { track.label }
        </div>
      )}, 
      {
        position: "top-center",
        className: "call-toast height-auto",
        hideProgressBar: true,
        closeButton: false,
        pauseOnFocusLoss: false
      });
  }

  onLocalUserVideoStream() {
    this.setState({ is_video_on: true });
  }

  onScreenStreamEnded() {
    this.viewing_screen = false;
    this.setState({ viewing_screen: false });
  }

  onJoined(params) {
    var { user_id } = params;

    this.updateParticipantsData();
    var user = this.getUser(user_id);

    this.setState({
      is_video_on: this.rtc_client.is_video_on,
      is_audio_on: this.rtc_client.is_audio_on,
    });

    if (user_id == this.state.current_user.id) {
      this.haveJoinedCall();
    
    } else {
      toast.info(`${user.name} ${i18next.t("has joined")}`, {
        position: "bottom-left",
        className: "call-toast",
        autoClose: 3000
      });

      this.joined_sound.currentTime = 0;
      this.joined_sound.play();
    }
  }

  async onLeft(params) {
    var { user_id, join_main_room = true } = params;

    this.logEventToServer("onLeft " + user_id);

    if (user_id == this.current_user.id) {
      if (!join_main_room) this.breakout_room = false;
      if (this.breakout_room) this.haveLeftBreakoutRoom();

      this.haveLeftCall();
    } else {
      this.updateParticipantsData();
    }
  }

  onPostJoinData() {
    this.updateParticipantsData();
  }

  onDisconnected() {
    this.logEventToServer("disconnected");
  }

  onReconnecting(params) {
    var { user_id } = params;

    if (user_id != this.current_user.id) return;

    this.logEventToServer("reconnecting");

    this.setState({ is_reconnecting: true });
  }

  onReconnected(params) {
    var { user_id } = params;

    if (user_id != this.current_user.id) return;

    this.logEventToServer("reconnected");

    this.setState({ is_reconnecting: false });
  }

  onReconnectionFailed() {
    this.logEventToServer("reconnection_failed");

    this.haveLeftCall();
  }

  onScreenShared(params) {
    var { user_id } = params;

    this.updateParticipantsData();
    var user = this.getUser(user_id);

    if (user_id == this.current_user.id) {
      this.setState({ sharing_screen: true });
    
    } else {
      toast.info(`${user.name} ${i18next.t("has shared screen")}`, {
        position: "bottom-left",
        className: "call-toast",
      });
    }
  }

  onPotentialScreenVideo({ user_id }) {
    if (this.viewing_screen || this.state.sharing_screen) return;
    if (user_id == this.current_user.id) return;

    this.rtc_client.getScreenStream({ user_id });
    this.viewing_screen = true;
  }

  onShareScreenStopped(params) {
    var { user_id } = params;

    this.updateParticipantsData();

    if (user_id == this.current_user.id) {
      this.setState({ sharing_screen: false });
    }
  }

  onVideoError(params) {
    var { error } = params;

    var os_name = this.device.os && this.device.os.name;
    var mac_camera_settings_link =
      "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera";
    var message = "Please check your camera settings";

    if (os_name == "Mac") {
      message = `Please check your 
                  <a 
                    href='${mac_camera_settings_link}' 
                    target='_blank' style="color: white; text-decoration: underline">
                    
                    camera settings
                  </a>`;
    }

    this.setState({ permission_check: true });
    toast.error(() => {
      return (
        <div>
          <b>Cannot start video</b>
          <br />

          <span dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      );
    });
  }

  onAudioError(params) {
    var { error } = params;

    var os_name = this.device.os && this.device.os.name;
    var mac_camera_settings_link =
      "x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone";
    var message = "Please check your microphone settings";

    if (os_name == "Mac") {
      message = `Please check your 
                  <a 
                    href='${mac_camera_settings_link}' 
                    target='_blank' 
                    style="color: white; text-decoration: underline">
                  
                    microphone settings
                  </a>`;
    }

    this.setState({ permission_check: true });
    toast.error(() => {
      return (
        <div>
          <b>Cannot start audio</b>
          <br />

          <span dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      );
    });
  }

  onShareScreenError(params) {
    var { error } = params;

    var os_name = this.device.os && this.device.os.name;

    if (os_name != "Mac") return;
    if (error.message.toLowerCase() != "permission denied by system") return;

    var app_name =
      process.env.REACT_APP_ENVOIRNMENT_KEY == "web"
        ? "Your browser"
        : process.env.REACT_APP_NAME;

    var mac_preferences_link =
      "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture";
    var message = `${app_name} might not have screen-recording permission on your computer.`;
    message += ` Please enable it in <a href='${mac_preferences_link}' target='_blank'>System Preferences</a>.`;

    common.showAlert({
      title: i18next.t("Can share your screen"),
      html: message,
    });
  }

  onVideoSwitched(params) {
    var { user_id, is_video_on } = params;

    this.updateParticipantsData();

    if (user_id == this.state.current_user.id) {
      var call_options = this.state.call_options;
      call_options.video.muted = !is_video_on;

      this.setState({ is_video_on, call_options });
    }
  }

  onAudioSwitched(params) {
    var { user_id, is_audio_on } = params;

    this.updateParticipantsData();

    if (user_id == this.state.current_user.id) {
      var call_options = this.state.call_options;
      call_options.audio.muted = !is_audio_on;

      this.setState({ is_audio_on, call_options });
    }
  }

  onVideoMutedByMe() {
    this.setState({ is_video_on: false });
  }

  onAudioMutedByMe() {
    this.setState({ is_audio_on: false });
  }

  //user has started the call
  onCallStarted() {
    this.logEventToServer("started");
  }

  onCallEnded(params) {
    this.logEventToServer("ended");

    params.user_id = this.current_user.id;
    this.onLeft(params);
  }

  onRemoved(params) {
    var { is_blocked, is_waiting } = params;

    this.logEventToServer("removed_by_host");

    if (is_blocked) {
      this.user_status = "blocked";
    } else if (is_waiting) {
      this.user_status = "waiting";
    } else {
      this.user_status = "removed";
    }
  }

  onRecordingStarted() {
    this.updateParticipantsData();
  }

  onRecordingStopped() {
    this.updateParticipantsData();
  }

  onUsersAdded(params) {
    var { users } = params;

    users.forEach((user) => {
      window.video_streams_manager.addWaitingUser({ user });
    });
  }

  onSocketConnected(params) {}
  onSocketDisconnected(params) {}
  onLeaveCall(params) {}

  onSocketSent(params) {
    var { event, data = {} } = params;

    this.logEventToLocal({
      event: `socket-sent ${event}`,
      data,
    });
  }

  onSocketReceived(params) {
    var { event, data = {} } = params;

    this.logEventToLocal({
      event: `socket-received ${event}`,
      data,
    });
  }

  onCallback(params) {
    var { callback_name = "", data = {} } = params;

    // no need to log these callbacks as we are already logging them in detail
    if (
      callback_name.indexOf("SocketSent") >= 0 ||
      callback_name.indexOf("SocketReceived") >= 0
    )
      return;

    this.logEventToLocal({
      event: `callback ${callback_name}`,
      data,
    });
  }

  updateParticipantsData() {
    var participants = this.rtc_client.participants;

    if (!participants) return;

    var screen_sharing_users = {};
    var recording_users = {};
    var video_on_users = {};
    var audio_on_users = {};
    var participants_ids = JSON.parse(
      JSON.stringify(this.rtc_client.participants_ids)
    );
    // need to move current user on top, will be helpful in pagination
    participants_ids = participants_ids.filter(
      (participant_user_id) => participant_user_id != this.current_user.id
    );
    participants_ids.unshift(this.current_user.id);

    Object.values(participants).forEach((participant) => {
      if (participant.is_sharing)
        screen_sharing_users[participant.id] = participant.user;
      if (participant.is_recording)
        recording_users[participant.id] = participant.user;
      if (participant.is_video_on)
        video_on_users[participant.id] = participant.user;
      if (participant.is_audio_on)
        audio_on_users[participant.id] = participant.user;
    });

    this.setState({
      participants,
      participants_ids,
      screen_sharing_users,
      recording_users,
      audio_on_users,
      video_on_users,
    });

    bus.dispatch("call__participants_data_updated", { participants });

    return participants_ids;
  }

  async joinBreakoutRoom({ breakout_room }) {
    this.user_action = "join-breakout-room";
    this.breakout_room_to_join = breakout_room;

    this.rtc_client.leaveCall();
  }

  leaveBreakoutRoom() {
    if (!this.breakout_room) return;

    this.haveLeftBreakoutRoom();

    this.rtc_client.leaveCall();

    if (this.call_breakout_rooms_ref.current) {
      this.call_breakout_rooms_ref.current.leaveBreakoutRoom();
    }
  }

  haveLeftBreakoutRoom() {
    this.user_action = "leave-breakout-room";
  }

  endBreakoutRooms() {
    return;
    this.call_breakout_rooms_ref.current.endBreakoutRooms();
  }

  endMainRoom() {
    // host is in main room
    // no need to end manually as it will get ended automatically with @function endCall in @component CallActions
    if (!this.breakout_room) return;

    this.rtc_client.rtc_helpers.deleteRoom({
      room_id: this.state.call.main_room_id,
    });

    return true;
  }

  getRecentShareScreenStream() {
    var recent_share_screen_time = "";
    var recent_share_screen_user_id = false;

    Object.keys(this.state.screen_sharing_users).forEach((user_id) => {
      var participant = this.state.participants[user_id];
      var share_screen_time = participant.is_sharing;

      if (share_screen_time > recent_share_screen_time) {
        recent_share_screen_time = recent_share_screen_time;
        recent_share_screen_user_id = user_id;
      }
    });

    if (!recent_share_screen_user_id) return false;

    this.rtc_client.getScreenStream({ user_id: recent_share_screen_user_id });

    return true;
  }

  //user has joined the call
  haveJoinedCall() {
    this.logEventToServer("joined");

    this.user_status = "";
    this.user_action = "";

    this.setState({
      call_joined: true,
    });

    bus.dispatch("call__joined", this.call);

    // setTimeout(() => {
    //   this.setState({ show_ad: true });
    // }, 2000);
  }

  haveLeftCall() {
    if (this.have_left_call) return;

    this.have_left_call = true;
    var soft_leave =
      this.breakout_room ||
      this.breakout_room_to_join ||
      this.user_status != "";

    console.log("haveLeftCall", soft_leave);

    this.logEventToServer("left");

    this.maximizeCall();

    bus.dispatch("call_recorder__stop" + this.identity);
    bus.dispatch("call__left", {
      user_status: this.user_status,
      user_action: this.user_action,
      breakout_room: this.breakout_room_to_join,
      is_video_on: this.state.is_video_on,
      is_audio_on: this.state.is_audio_on,
      soft_leave,
    });

    clearInterval(this.network_interval_id);

    this.setState({ call_joined: false });
  }

  keepScreenAwake(keep_awake = true) {
    if (!window.ipcRenderer) return;

    window.ipcRenderer.send("keep-screen-awake", keep_awake);
  }

  updateParticipant(user_id, data) {
    var index = this.state.participants.findIndex(
      (item) => item && item.id === user_id
    );
    var participants = this.state.participants;
    var participant = participants[index];
    participants[index] = { ...participant, ...data };

    this.setState({ participants: participants });
  }

  addToHostIds(user_id) {
    var host_ids = this.state.host_ids;

    if (host_ids.indexOf(user_id) > -1) return;

    host_ids.push(user_id);

    this.setState({ host_ids: host_ids });
  }

  removeFromHostIds(user_id) {
    var host_ids = this.state.host_ids;

    var index = host_ids.indexOf(user_id);
    if (index == -1) return;

    host_ids.splice(index, 1);

    this.setState({ host_ids: host_ids });
  }

  showActions() {
    this.setState({ show_actions: true });
  }

  hideActions() {
    this.setState({ show_actions: false });
  }

  getUser(user_id) {
    var user = false;

    var participant = this.state.participants[user_id];
    if(participant) {
      user = participant.user;
    }

    return user || {};
  }

  updateRoomAppData(get_data_callback) {
    this.rtc_client.getRoom().then((room) => {
      var app_data = room.app_data;
      var new_app_data = get_data_callback(app_data);

      this.rtc_client.updateAppData({ app_data: new_app_data });
    });
  }

  async saveRoomDataInCache({ room_id, data = {}, get_data_callback = false }) {
    var room_id = room_id || this.call.room_id;
    var cache_data_key = `${room_id}_cache_data`;

    var json_data = await this.rtc_client.rtc_helpers.getFromCache({
      key: cache_data_key,
    });
    var old_data = json_data ? JSON.parse(json_data) : {};

    if (get_data_callback) {
      data = get_data_callback(old_data);
    }

    var room_data = { ...old_data, ...data };

    if (!this.rtc_client.rtc_helpers) return;

    this.rtc_client.rtc_helpers.saveToCacheTemporarily({
      key: cache_data_key,
      value_json: JSON.stringify(room_data),
      seconds: 86400,
    });
  }

  async getRoomDataFromCache({ room_id }) {
    var room_id = room_id || this.call.room_id;
    var cache_data_key = `${room_id}_cache_data`;

    return this.rtc_client.rtc_helpers
      .getFromCache({ key: cache_data_key })
      .then((json_data) => {
        return json_data ? JSON.parse(json_data) : {};
      });
  }

  initJoin = ({ is_video_on, is_audio_on }) => {
    let call_options = this.state.call_options;
    call_options.video.muted = !is_video_on;
    call_options.audio.muted = !is_audio_on;

    this.setState({ call_options });
    this.setState({ join_options_passed: true });

    bus.dispatch("call__going_to_join");
  };

  cancelJoin() {
    bus.dispatch("call__join_cancelled");
  }

  minimizeCall() {
    return;
    if(process.env.REACT_APP_ENVOIRNMENT_KEY == 'app') return;
    
    common.exitFullScreen();
    this.setState({ is_full_screen: false });
    $("body").removeClass("handling-call");
    $("body").addClass("call-minimized");
  }

  maximizeCall() {
    $("body").addClass("handling-call");
    $("body").removeClass("call-minimized");

    bus.dispatch('call__maximized');
  }

  async observeNetwork() {
    clearInterval(this.network_interval_id);

    var network_score = await this.checkNetworkScore();
    this.setState({ slow_network_start: network_score < 2 });
    setTimeout(() => {
      this.setState({ slow_network_start: false });
    }, 5000);

    // continuous detection
    this.network_interval_id = setInterval(async () => {
      var network_score = await this.checkNetworkScore();

      this.rtc_client.updateNetworkScore({ score: network_score });

      this.setState({ slow_network: network_score < 2 });
    }, 20000);
  }

  async checkNetworkScore() {
    var result = await common.checkNetworkSpeed();

    if (result.error) return 2;

    var score = Math.ceil(result.speed_mbps);

    if (score > 5) {
      score = 5;
    }

    return score;
  }

  async changeViewType(params) {
    var { view_type } = params;

    await this.setStateAsync({ view_type });
    window.video_streams_manager.changeViewType(params);
  }

  toggleFullScreen() {
    if (this.state.is_full_screen) {
      common.exitFullScreen();
      this.setState({ is_full_screen: false });

      return;
    }

    common.makeElementFullScreen($(".call-container")[0]);
    this.setState({ is_full_screen: true });
  }

  logEventToLocal(params) {
    var { event, data = {} } = params;

    if (process.env.REACT_APP_DEV_EMAILS.indexOf(this.current_user.email) == -1)
      return;

    data = JSON.parse(JSON.stringify(data));

    data.participants_ids = this.state.participants_ids;

    delete data.room;
    delete data.user;
    delete data.participants;
    delete data.has_stream;
    delete data.sdp;
    delete data.candidate;
    delete data.connect_parameters;
    delete data.produce_parameters;
    delete data.device;
    delete data.router;

    var message = `event: ${event}`;
    message += ` ${datetime.getUtcDate() + " " + datetime.getLocalDate()}`;
    message += `\r\n data: ${JSON.stringify(data)} \r\n \r\n`;

    this.local_logs += message;
  }

  downloadLocalLogs() {
    if (!this.local_logs) return;

    var file_name = `meeting-${
      this.call.id
    }-${datetime.getLocalDate()}-logs.txt`;

    common.saveFileLocally({
      file_name,
      content: this.local_logs,
    });
  }

  logEventToServer(event, by_user_id) {
    return;
    if (!event) return;

    this.logEventToLocal({ event });

    var logged_by_user = this.current_user;
    var by_user = by_user_id
      ? this.state.participants[by_user_id]
      : logged_by_user;

    var message = [
      `${event} event`,
      `by ${by_user.name}/${by_user.email}${by_user.id} ${JSON.stringify(
        by_user
      )}`,
      `logged by ${logged_by_user.name}/${logged_by_user.email}${
        logged_by_user.id
      } ${JSON.stringify(logged_by_user)}`,
    ];

    console.log("logEventToServer", message);

    var file_name = `calls/${this.call.type}-call-${this.call.id}`;
    if (common.getCurrentUser()) {
      axios.post("system/log_message", {
        message: message,
        file_name: file_name,
      });
    }
  }

  saveLocalCallSettings(options = {}) {
    var call_settings = this.getLocalCallSettings();
    call_settings = { ...call_settings, ...options };

    localStorage.setItem("call_settings", JSON.stringify(call_settings));
  }

  getLocalCallSettings() {
    var call_settings = localStorage.getItem("call_settings");
    return call_settings ? JSON.parse(call_settings) : {};
  }

  render() {
    return (
      <div
        id="video_screen"
        className={
          "call-container" +
          (this.state.side_panel_opened ? " side-panel-opened" : "") +
          (this.state.is_countdown_started ? " countdown-visible" : "") +
          (this.state.is_full_screen ? " full-screen" : "") +
          (this.state.viewing_screen ? " viewing-shared-screen" : "") +
          (this.state.is_minimized ? " is-minimized" : "") +
          (this.state.show_ad ? " showing-ad" : "") +
          (this.props.layout_type ? ` ${this.props.layout_type}-layout` : " main-layout")
        }>
        
        {this.state.call_joined &&
            
        <>
        <div className="call-header">
          <div className="left" title={ this.call_title }>
            <i class="fas fa-chevron-left go-back-btn" onClick={ () => this.minimizeCall() }></i>
            
            <span className="call-title" onClick={ () => this.minimizeCall() }>{ this.call_title }</span>
          </div>
         
          <div className="right">
            
            <div class="view-type dropdown dropup">
       
              <span className="dropdown-toggle" data-toggle="dropdown">
                {
                  this.state.view_type == 'speaker'
                   
                  ?

                  <i class="fas fa-window-maximize mr-1"></i>

                  :

                  <i class="fas fa-th mr-1"></i> 
                }

                { this.state.view_type == 'speaker' ? i18next.t("Speaker View") : i18next.t("Gallery View") }
              </span>

                  <div class="dropdown-menu">
                    <div
                      className="dropdown-item"
                      onClick={() => this.changeViewType({ view_type: "speaker" })}
                    >
                      {this.state.view_type == "speaker" && (
                        <i class="fa fa-check tick-mark"></i>
                      )}

                      <div className="main-content">
                        <i class="fas fa-window-maximize mr-2"></i>

                        {i18next.t("Speaker View")}
                      </div>
                    </div>

                    <div
                      className="dropdown-item"
                      onClick={() => this.changeViewType({ view_type: "gallery"})}
                    >
                      {this.state.view_type == "gallery" && (
                        <i class="fa fa-check tick-mark"></i>
                      )}

                      <div className="main-content">
                        <i class="fas fa-th mr-2"></i>

                        {i18next.t("Gallery View")}
                      </div>
                    </div>
                  </div>
                </div>

                <i
                  class={
                    "fas" +
                    (this.state.is_full_screen
                      ? " fa-compress-arrows-alt"
                      : " fa-expand-arrows-alt")
                  }
                  onClick={() => this.toggleFullScreen()}
                />
              </div>
            </div>

        <div className="call-body">
          <AudioManager
            current_user={ this.state.current_user }
            participants={ this.state.participants }
            participants_ids={ this.state.participants_ids }
            rtc_client={ this.rtc_client }
          />

          <VideoManager
            view_type={ this.state.view_type }
            current_user={ this.state.current_user }
            participants={ this.state.participants }
            participants_ids={ this.state.participants_ids }
            rtc_client={ this.rtc_client }
            layout_type={ this.props.layout_type }
          />

          {/* {
            this.is_basic_plan && window.innerWidth > 500 &&

            <div className="google-ad-container" style={{ position: 'relative', width: '150px', height: '500px' }}>
              <div className="sponsored">{ i18next.t("Sponsored") }</div>

              { this.state.show_ad &&
                <div className="google-ad" style={{ position: 'relative', width: '150px', height: '500px' }}>
                  <GoogleAd />
                </div>
              }
            </div>
          } */}

          <CallSharedScreen
            rtc_client={this.rtc_client}
            screen_sharing_users={this.state.screen_sharing_users}
            sharing_screen={this.state.sharing_screen}
            is_full_screen={this.state.is_full_screen}
            participants={this.state.participants}
            current_user={this.state.current_user}
            call_component={this}
            identity={this.identity}
            ref={this.shared_screen_ref}
          />

          <CallMaximizedStream
            rtc_client={this.rtc_client}
            participants={this.state.participants}
            call_component={this}
          />

          <CallParticipantsSection
            call={this.state.call}
            is_host={this.state.is_host}
            host_ids={this.state.host_ids}
            call_options={this.state.call_options}
            participants={this.state.participants}
            participants_ids={this.state.participants_ids}
            screen_sharing_users={this.state.screen_sharing_users}
            video_on_users={this.state.video_on_users}
            audio_on_users={this.state.audio_on_users}
            recording_users={this.state.recording_users}
            current_user={this.state.current_user}
            rtc_client={this.rtc_client}
            addToHostIds={this.addToHostIds.bind(this)}
            removeFromHostIds={this.removeFromHostIds.bind(this)}
          />

          {/* <CallChat
            is_host={this.state.is_host}
            chat={this.state.call.chat}
            record_id={this.record_id}
            current_user={this.current_user}
            participants={this.state.participants}
            participants_ids={this.state.participants_ids}
            breakout_room={this.breakout_room}
          /> */}

          {this.state.is_host && (
            <>
            <CallRaisedHands
              call={this.state.call}
              current_user={this.state.current_user}
              identity={this.identity}
              rtc_client={this.rtc_client}
            />

            <CallSpeakRequests />
            </>
          )}
        </div>

        <div className={ `call-footer ${!this.state.show_actions ? ' d-none' : ''}` }>
          <CallActions
            call={this.state.call}
            call_library={this.call_library}
            call_options={this.state.call_options}
            participants={this.state.participants}
            is_host={this.state.is_host}
            host_ids={this.state.host_ids}
            current_user={this.state.current_user}
            showChat={this.showChat}
            identity={this.identity}
            record_id={this.record_id}
            is_video_on={this.state.is_video_on}
            is_audio_on={this.state.is_audio_on}
            sharing_screen={this.state.sharing_screen}
            screen_sharing_users={this.state.screen_sharing_users}
            is_reconnecting={this.state.is_reconnecting}
            breakout_room={this.breakout_room}
            video_effect_type={ this.state.video_effect_type }
            layout_type={ this.props.layout_type }
            leaveBreakoutRoom={this.leaveBreakoutRoom.bind(this)}
            endBreakoutRooms={this.endBreakoutRooms.bind(this)}
            endMainRoom={this.endMainRoom.bind(this)}
            call_component={this}
            video_effects_ref={this.video_effects_ref}
            ref={this.actions_ref}
          />
        </div>
        </>
        }

        <CallTimeMaster
          call={this.state.call}
          call_options={this.state.call_options}
          is_host={this.state.is_host}
          host_ids={this.state.host_ids}
          current_user={this.state.current_user}
          identity={this.identity}
        />

        {this.state.time_check_passed && (
          <CallAuth
            call={this.state.call}
            call_options={this.state.call_options}
            current_user={this.state.current_user}
            host_ids={this.state.host_ids}
            identity={this.identity}
          />
        )}

        {this.state.current_user.is_authenticated && (
          <div className="w-100 h-100">
            {this.state.call_options.enable_join_options && (
              <JoinOptions
                call={this.state.call}
                initJoin={this.initJoin.bind(this)}
                cancelJoin={this.cancelJoin.bind(this)}
                call_options={this.state.call_options}
              />
            )}

            {this.state.permission_check == true ? (
              <PermissionAllowPopup />
            ) : null}

            <VideoEffects
              current_user={this.state.current_user}
              call_component={this}
              call_settings={this.call_settings}
              is_video_on={this.state.is_video_on}
              ref={this.video_effects_ref}
              tflite={this.props.tflite}
            />

            {this.state.join_options_passed && this.state.init_library && (
              <CallLibrary
                call={this.state.call}
                call_options={this.state.call_options}
                identity={this.identity}
                current_user={this.state.current_user}
                video_effects_ref={this.video_effects_ref}
                callLibraryInitiated={this.callLibraryInitiated.bind(this)}
              />
            )}
          </div>
        )}

        {this.state.call_joined && (
          <>
            <ShareScreenPicker identity={this.identity} />

            {/* <CallBreakoutRooms
              rtc_client={this.rtc_client}
              participants={this.state.participants}
              current_user={this.state.current_user}
              call={this.state.call}
              identity={this.identity}
              host_ids={this.state.host_ids}
              breakout_room={this.breakout_room}
              joinBreakoutRoom={this.joinBreakoutRoom.bind(this)}
              getRoomDataFromCache={this.getRoomDataFromCache.bind(this)}
              call_component={this}
              ref={this.call_breakout_rooms_ref}
            /> */}

            <CallRecorder
              recording_users={this.state.recording_users}
              call={this.state.call}
              rtc_client={this.rtc_client}
              participants={this.state.participants}
              current_user={this.state.current_user}
              call_library={this.call_library}
              call_component={this}
              shared_screen_ref={this.shared_screen_ref}
            />

            <Whiteboard
              call_library={this.call_library}
              sharing_screen={this.state.sharing_screen}
              identity={this.identity}
            />

            <AddTimeToCall
              call={this.state.call}
              call_options={this.state.call_options}
            />

            <CallReactions />

            {this.state.is_host && (
              <AddToCall
                call={this.state.call}
                call_options={this.state.call_options}
                participants={this.state.participants}
                participants_ids={this.state.participants_ids}
                current_user={this.state.current_user}
                identity={this.identity}
              />
            )}
          </>
        )}
        
        <div className="maximize-call-btn" onClick={this.maximizeCall.bind(this)}>
          <i class="fas fa-phone-alt"></i> &nbsp;&nbsp;
          {i18next.t("Return to Meeting")}
        </div>
      </div>
    );
  }
}
