import React from 'react';
import { withTranslation } from 'react-i18next';
import bus from '../bus';
import i18next from "i18next";
import '../../assets/css/call/call_participant.css';
import '../../assets/css/call/call_speak_requests.css';

 class CallSpeakRequests extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            show_me: false,
            users_requested_speak: []
		};

        window.speaker_requests = this;
	}

	componentDidMount() {
        this.attachBusListeners();
        this.attachCallListeners();
        this.attachSocketListeners();
	}

    componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
	    this.setState = (state, callback)=>{
	        return;
	    };
	}

    attachBusListeners() {
        bus.on('side_panel__hide_all', () => {
            this.hideMe();
        });

        bus.on('speaker_requests__toggle', () => {
            if(this.state.show_me) {
                this.hideMe();
                return;
            }

            this.showMe();
        });
    }

    attachCallListeners() {
        window.rtc_client.onLeft = this.onLeft.bind(this);
    }

    attachSocketListeners() {
        window.rtc_client.socket.on('request-to-speak', (params) => {
            this.onRequestToSpeak(params);
        });

        window.rtc_client.socket.on('cancel-request-to-speak', ({ user_id }) => {
            this.removeSpeakRequest(user_id);
        });
    }

    onRequestToSpeak({ user_id }) {
        var user = window.call_component.getUser(user_id);
        
        var users_requested_speak = [user, ...this.state.users_requested_speak];
        this.setState({ users_requested_speak });

        bus.dispatch('call_speaker_requests__updated', users_requested_speak.length);
    }

    onLeft(params) {
        var { user_id } = params;

        this.removeSpeakRequest({ user_id });
    }

    removeSpeakRequest({ user_id }) {
        var users_requested_speak = this.state.users_requested_speak.filter(function(userrr) { 
			return userrr.id != user_id;
		});
        
        this.setState({ users_requested_speak });

        bus.dispatch('call_speaker_requests__updated', users_requested_speak.length);
    }

    allowToSpeak(user_id) {
        window.rtc_client.notifyTheseUsers({
            event: "allowed-to-speak",
            user_ids: [user_id]
        });

        this.removeSpeakRequest({ user_id });
    }

    showMe() {
        bus.dispatch('side_panel__hide_all');

        bus.dispatch(
            'side_panel__visibility_changed', 
            { is_shown: 1, section_name: 'raised-hands'}
        );

        this.setState({ show_me: true });
    }

    hideMe() {
        bus.dispatch(
            'side_panel__visibility_changed', 
            { is_shown: 0, section_name: 'raised-hands'}
        );

        this.setState({ show_me: false });
    }

	render() {
		return (
        <div className={"side-panel speak-requests-section" + (this.state.show_me ? '' : ' d-none')}> 
        
            <div className='side-panel-header'>
                <span>{ i18next.t("Speak Requests") }</span>

                <div className='actions'>
                    <i class="fas fa-times action" onClick={ () => this.hideMe() }></i>
                </div>
            </div>
            
            <div className='side-panel-body speak-requests-container'>
                { this.state.users_requested_speak.map(user => {

                    return (
                    <div className="participant">
                        <div className="left-side w-100">
                            <img
                                className="profile-picture"
                                src={user.profile_picture_url}
                            />

                            <span className="full-name">
                                { user.first_name + ' ' + user.last_name }
                            </span>
                        </div>

                        <div className='right-side text-right'>
                            <button className='button btn-info' onClick={ () => this.allowToSpeak(user.id) }>
                                { i18next.t("Allow To Speak") }
                            </button>
                        </div>
                    </div>
                    )

                })}

                {  
                    !this.state.users_requested_speak.length &&

                    <i className='no-record'>
                        {i18next.t("No request")}
                    </i>
                }
            </div>
        </div>
        )
	}
}

export default withTranslation()(CallSpeakRequests);