import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputAdornment,
  TextField,
  Button,
  IconButton,
  TextareaAutosize,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import { withTranslation } from "react-i18next";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useTranslation } from "react-i18next";
import { defaultTheme } from "./../../assets/js/custom-color";
import { useSelector } from "react-redux";
import axios from "axios";
import bus from "../bus";
import * as common from "../../assets/js/helpers/common";
import "../../assets/css/messages/add_message.css";
import {ProgressBar } from "react-bootstrap"

const styles = (theme) => ({
  inputField: {
    notchedOutline: { border: "none" },
    borderRadius: "2rem",
    "& input::placeholder": {
      fontSize: "0.8rem",
    },
  },
  inputContainer: {
    borderRadius: "2rem",
  },
});

const useStyles = makeStyles(styles);

const AddMessage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showText, setShowText] = React.useState(true);
  const [hostText, setHostText] = React.useState("");
  const [valueField, setValueField] = React.useState("0");
  const [src, setSrc] = useState(null);
  const typeMessage = t("Type message here") + ".....";
  const [message, setMessage] = useState("");
  const [send_underway, setSendUnderway] = useState(false);
  const auth = useSelector((state) => state.auth);
  const token = auth.login_token;
  const { content, updatemessagestate, id, current_user, host, participants } =
    props;
  const [participant, setParticipant] = React.useState([]);
  const [participantIds, setParticipantIds] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [progress, setProgress] = useState();
  const type = "meeting";
  let selectId = id;
  var group_id = "";
  var to_id = "";
  var meeting_id = "";

  const handleChange = (event) => {
    const data = event;
    data == 0 ? setShowText(true) : setShowText(false);
    host == true ? setHostText("") : setHostText("");
    setTitle(data);
    setValueField(event);
  };

  useEffect(() => {
    let arr = [];
    let arrIds = [];
    const entries = Object.values(props.participants);

    for (var i = 0; i < entries.length; i++) {
      if (entries[i].user.id != props.current_user.id) {
        arr.push(entries[i].user);
        arrIds.push(parseInt(entries[i].user.id));
      }
    }

    const everyArray = { id: "0", first_name: t("Everyone") };
    const primeArray = [everyArray, ...arr];
    setParticipant(primeArray);
    setParticipantIds(arrIds);
  }, [props.participants_ids]);

  const messageEntered = (e) => {
    setMessage(e.target.value);
  };

  const handleOnPaste = (event) => {
    if (src) {
      return false;
    }
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    var pastedData = event.clipboardData.getData("Text");
    let blob = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile();
      }
    }
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSrc(event.target.result);
      };
      reader.readAsDataURL(blob);
      setMessage("");
    }
  };

  const uploadfile = (e) => {
    const file = e.target.files[0];
    if (file == undefined) {
      return false;
    }
    console.log("On upload submit", participantIds);
    if (common.getCurrentUser()) {
      var msg_api_url = "messages/send_message";
      var file_api_url = "file_storage/upload_file";
      var user_author = common.getCurrentUser();
    } else if (common.getDummyUser()) {
      var file_api_url = "file_storage/upload_guest_file";
      var msg_api_url = "messages/send_guest_message";
      var dummy_username = common.getDummyUser();
      var user_name = dummy_username.first_name;
      var user_author = common.getDummyUser();
    } else {
      return;
    }

    if (type == "group") group_id = selectId;
    else if (type == "private") to_id = selectId;
    else if (type == "meeting") {
      meeting_id = id;
      to_id = valueField;
    }

    let formData = new FormData();
    formData.append("directory", "messages");
    formData.append("file", file);
    formData.append("ignore_encryption", true);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(file_api_url, formData, config)
      .then((responce) => {
        
        var api_data = {
          file_url: responce.data.data[0],
          to_id: to_id,
          group_id: group_id,
          meeting_id: meeting_id,
          participant_ids: participantIds,
        };

        var data = {
          file_url: responce.data.data[0],
          to_id: to_id,
          group_id: group_id,
          meeting_id: meeting_id,
          participant_ids: participantIds,
          url: responce.data.data[0]
        };

        if (common.getDummyUser()) {
          data["user_name"] = user_name;
          api_data["user_name"] = user_name;
        }
        data["file_type"] = common.file_type(responce.data.data[0]);
        data["event_name"] = "new_message_meeting_"+meeting_id;
        data["user_ids"]= participantIds;
        data["user_id"] = user_author.id;
        data["author"] = user_author;
        data["user_name"]= user_name;
        data["group_id"]= 0;
        data["language_id"]= 1;
        data["file_language_id"]= null;
        let date =  new Date();
        let utc_date = date.getUTCFullYear()+"-"+(date.getUTCMonth()+1)+"-"+date.getUTCDate()+" "+date.getUTCHours()+":"+date.getUTCMinutes()+":"+date.getUTCSeconds(); //'2023-10-10 16:12:18';
        data["date"] = utc_date
        data["updated_at"]= utc_date;
        
        console.log(data);
        window.socket.emit("send_message_user", JSON.stringify(data));
        bus.dispatch("add_message__message_sent", data);
        console.log("ehsan",responce.data, data, api_data);
        axios
          .post(msg_api_url, api_data)
          .then((responce) => {
            if (updatemessagestate) updatemessagestate(responce.data.data);
            // bus.dispatch("add_message__message_sent", responce.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hideImage = () => {
    setSrc(null);
  };

  const submitForm = (e) => {
    if (common.getCurrentUser()) {
      var user_author = common.getCurrentUser();
      var msg_api_url = "messages/send_message";
      var file_api_url = "file_storage/upload_file";
      var user_name = user_author.first_name;
    } else if (common.getDummyUser()) {
      var msg_api_url = "messages/send_guest_message";
      var file_api_url = "file_storage/upload_guest_file";
      var dummy_username = common.getDummyUser();
      var user_name = dummy_username.first_name;
      var user_author = common.getDummyUser();
    } else {
      return;
    }

    // return false;
    e.preventDefault();
    message.trim();
    if (
      (message === null && src == null) ||
      (message.match(/^ *$/) !== null && src == null)
    )
      return;

    if (!message && src == null) return;
    if (valueField !== "0") {
      selectId = valueField;
      if (type == "group") group_id = selectId;
      else if (type == "private") to_id = selectId;
      else if (type == "meeting") {
        meeting_id = id;
        to_id = valueField;
      }
    } else {
      selectId = id;
      if (type == "group") group_id = selectId;
      else if (type == "private") to_id = selectId;
      else if (type == "meeting") {
        meeting_id = id;
        to_id = valueField;
        // meeting_id = selectId;
        // to_id = valueField;
      }
    }

    setSendUnderway(true);

    if (src !== null) {
      fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          let formData = new FormData();
          formData.append("directory", "messages");
          formData.append("file", file);
          formData.append("ignore_encryption", true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            }
            // onUploadProgress: (data) => {
            //   setProgress(Math.round((100 * data.loaded) / data.total))
            //   console.log("progress data", data);
            // },
          };
          axios
            .post(file_api_url, formData, config)
            .then((responce) => {
              var api_data = {
                file_url: responce.data.data[0],
                to_id: to_id,
                group_id: group_id,
                meeting_id: meeting_id,
                participant_ids: participantIds,
              };

              var data = {
                file_url: responce.data.data[0],
                to_id: to_id,
                group_id: group_id,
                meeting_id: meeting_id,
                participant_ids: participantIds,
              };

              if (common.getDummyUser()) {
                data["user_name"] = user_name;
                api_data["user_name"] = user_name;
              }
              data["file_type"] = "image";
              data["event_name"] = "new_message_meeting_"+meeting_id;
              data["user_ids"]= participantIds;
              data["user_id"] = user_author.id;
              data["author"] = user_author;
              data["user_name"]= user_name;
              data["group_id"]= 0;
              data["language_id"]= 1;
              data["file_language_id"]= null;
              let date =  new Date();
              let utc_date = date.getUTCFullYear()+"-"+(date.getUTCMonth()+1)+"-"+date.getUTCDate()+" "+date.getUTCHours()+":"+date.getUTCMinutes()+":"+date.getUTCSeconds(); //'2023-10-10 16:12:18';
              data["date"] = utc_date
              data["updated_at"]= utc_date;
              console.log(data);
              window.socket.emit("send_message_user", JSON.stringify(data));
              bus.dispatch("add_message__message_sent", data);
              // debugger;
              axios
                .post(msg_api_url, api_data)
                .then((responce) => {
                  if (updatemessagestate)
                    updatemessagestate(responce.data.data);
                  setSendUnderway(false);
                  setSrc(null);
                  
                })
                .catch((error) => {
                  console.log(error, "check err");
                });
            })
            .catch((error) => {
              console.log(error, "check err");
            });
        });
    } else {
      var api_data = {
        text: message,
        to_id: to_id,
        group_id: group_id.at,
        meeting_id: meeting_id,
        user_id: meeting_id,
        participant_ids: participantIds,
      };

      if (common.getDummyUser()) {
        api_data["user_name"] = user_name;
        data["user_name"] = user_name;
      }

      var data = {
        text: message,
        to_id: to_id,
        group_id: group_id.at,
        meeting_id: meeting_id,
        user_id: meeting_id,
        participant_ids: participantIds,
      };
      // data["event_name"] = "new_message_meeting_"+meeting_id;
      data["event_name"] = "new_message_meeting_"+meeting_id;
      data["user_ids"]= participantIds;
      data["user_id"] = user_author.id;
      data["author"] = user_author;
      data["user_name"]= user_name;
      data["group_id"]= 0;
      data["language_id"]= 1;
      data["file_language_id"]= null;
      let date =  new Date();
      let utc_date = date.getUTCFullYear()+"-"+(date.getUTCMonth()+1)+"-"+date.getUTCDate()+" "+date.getUTCHours()+":"+date.getUTCMinutes()+":"+date.getUTCSeconds(); //'2023-10-10 16:12:18';
      data["date"] = utc_date
      data["updated_at"]= utc_date;
     window.socket.emit("send_message_user", JSON.stringify(data));
     bus.dispatch("add_message__message_sent", data);
      axios
        .post(msg_api_url, api_data)
        .then((responce) => {
          setSendUnderway(false);
          if (updatemessagestate) updatemessagestate(responce.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setMessage("");
  };
//Yoonus added to submit form if enter is pressed without shift key
  const handleKeyPress = (event)=>{
    if(event.key === 'Enter' && !event.shiftKey){
      submitForm(event)
    }
  }

  if(props.chat == "off" || (props.chat == "host_only" && !props.host)) {
    return (
      <div className="add-message disabled">
        <p className="m-0 p-0">
          {t("Chat is disabled by host")}
        </p>
      </div>
    )
  }

  return (
    <>
      <div className="add-message">
        <form
          style={{
            flexGrow: 1,
          }}
          onSubmit={submitForm}
        >
          {src !== null ? (
            <div className="fluid-container">
              <ul className="imagePreview-list">
                <li>
                  <Avatar
                    className="hide-image"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <HighlightOffIcon
                      onClick={hideImage}
                      style={{ fontSize: 25 }}
                    />
                  </Avatar>
                  <img className="defaultImage" src={src} alt="image" />
                </li>
              </ul>
            </div>
          ) : null}
     
          <div className="massagefield-holder">
            <div className=" direct-message-select">
              <Box className="d-flex options-container">
                <FormControl className="options">
                  <InputLabel
                    className="custom-messageField-label"
                    variant="standard"
                    htmlFor="uncontrolled-native"
                  >
                    <strong>{t("To")} </strong>
                  </InputLabel>

                  <NativeSelect
                    defaultValue={407}
                    onChange={(e) => handleChange(e.target.value)}
                    style={{ marginLeft: "30px", marginTop: "0" }}
                    inputProps={{
                      name: "meeting_userId",
                      id: "uncontrolled-native",
                    }}
                  >
                    {participant.map((data) => (
                      <option value={data.id}>{data.first_name}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              
                <InputAdornment position="end" className="attachment-container">
                  <input
                    name="message-file"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={uploadfile}
                  />

                  <label
                    htmlFor="icon-button-file"
                    class="icon-button-file-label"
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AttachFileIcon
                        style={{
                          color: "#ADAEAF",
                        }}
                      />
                    </IconButton>
                  </label>
                </InputAdornment>
              </Box>
            </div>

            <FormControl className="input-field-container">
              <ThemeProvider theme={defaultTheme}>
                {/* <TextField
									size="small"
									margin="none"
									classes={{
										root: classes.inputContainer,
									}}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutline,
											root: classes.inputField,
										}
									}}
									id="input-field"
									placeholder={typeMessage}
									fullWidth
									disabled={chat}
									type="text"
									onPaste={handleOnPaste}
									autoComplete="current-password"
									variant="outlined"
									name="message"
									autoFocus
									value={message}
									onChange={messageEntered}
								/> */}

                <TextareaAutosize
                  minRows={2}
                  maxRows={2}
                  margin="none"
                  classes={{
                    root: classes.inputContainer,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      root: classes.inputField,
                    },
                  }}
                  id="input-field"
                  placeholder={typeMessage}
                  fullWidth
                  type="text"
                  onPaste={handleOnPaste}
                  onKeyPress={handleKeyPress} 
                  autoComplete="current-password"
                  variant="outlined"
                  name="message"
                  autoFocus
                  value={message}
                  onChange={messageEntered}
                />             
                {/* code written by Fauz ali 27/10/2021 */}
              </ThemeProvider>
            </FormControl>
             
            <button type="submit" className="send-btn" disabled={send_underway}>
              <i class="fas fa-paper-plane"></i>
            </button>
            {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                {progress} 
          </div>
        </form>
      </div>
    </>
  );
};

export default withTranslation()(AddMessage);
