// BandwidthHandler.js

var BandwidthHandler = (function() {
    var isMobileDevice = !!navigator.userAgent.match(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile/i);
    if (typeof cordova !== 'undefined') {
        isMobileDevice = true;
    }

    if (navigator && navigator.userAgent && navigator.userAgent.indexOf('Crosswalk') !== -1) {
        isMobileDevice = true;
    }

    function setBandwidth(sdp, bandwidth) {
        if (!bandwidth) {
            return sdp;
        }

        if (typeof isFirefox !== 'undefined' && isFirefox) {
            return sdp;
        }

        if (isMobileDevice) {
            return sdp;
        }

        if (bandwidth.audio && bandwidth.audio.max) {
            sdp = setBAS(sdp, 'audio', bandwidth.audio.max);
        }

        if (bandwidth.video && bandwidth.video.max) {
            sdp = setBAS(sdp, 'video', bandwidth.video.max);
        }

        if (bandwidth.video && bandwidth.video.min) {
            sdp = setMediaBitrates(sdp, { min: bandwidth.video.min, max: bandwidth.video.max });
        }

        return sdp;
    }

    function setBAS(sdp, media, bitrate) {
        var lines = sdp.split("\n");
        var line = -1;
        
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("m=" + media) === 0) {
                line = i;
                break;
            }
        }
        
        if (line === -1) {
            return sdp;
        }
       
        // Pass the m line
        line++;
       
        // Skip i and c lines
        while(lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
            line++;
        }
       
        // If we're on a b line, replace it
        if (lines[line].indexOf("b") === 0) {
            lines[line] = "b=AS:" + bitrate;
            return lines.join("\n");
        }
        
        // Add a new b line
        var newLines = lines.slice(0, line)
        newLines.push("b=AS:"+bitrate)
        newLines = newLines.concat(lines.slice(line, lines.length))
        
        return newLines.join("\n")
    }

    // Sets x-google-*-bitrate params to sdp to controls min, max, start bandwidth
    function setMediaBitrates(sdp, params) {
        params = params || {};
        var xgoogle_min_bitrate = params.min;
        var xgoogle_max_bitrate = params.max;
        var xgoogle_start_bitrate = params.start || xgoogle_min_bitrate;

        var bitrate_part = '';

        // adding new bitrate params
        if(xgoogle_min_bitrate)
            bitrate_part += 'x-google-min-bitrate=' + xgoogle_min_bitrate + ';'
        if(xgoogle_start_bitrate)
            bitrate_part += 'x-google-start-bitrate=' + xgoogle_start_bitrate + ';';
        if(xgoogle_max_bitrate)
            bitrate_part += 'x-google-max-bitrate=' + xgoogle_max_bitrate + ';';

        if(!bitrate_part) return;

        // removing old bitrate params from line to avoid duplication, removing trivial colon and white space at end also
        // during processing sometime last new line is removed so preserving that
        sdp = sdp.replace(/\r\n\s*$/, "last-new-line");
        sdp = sdp.replace(/x-google-max-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-min-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-start-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-max-bitrate=\d+/g, '');
        sdp = sdp.replace(/x-google-min-bitrate=\d+/g, '');
        sdp = sdp.replace(/x-google-start-bitrate=\d+/g, '');
        sdp = sdp.replace(/;\s*$/, "");
        sdp = sdp.replace(/ \s*$/, "");

        var sdp_lines = sdp.split('\r\n');
        sdp_lines.forEach((line, i) => {
            if (/^a=fmtp:\d*/.test(line)) {
                line += ';' + bitrate_part;

                // replacing last-new-line back to new line
                if(line.indexOf('last-new-line') !== -1) {
                    line = line.replace('last-new-line', '');
                    line += '\r\n';
                }

                sdp_lines[i] = line;
            }
        });

        // console.log('sdp_lines', sdp_lines);

        sdp = sdp_lines.join('\r\n');
        // replacing last-new-line back to new line incase it is not replaced already
        sdp = sdp.replace('last-new-line', '\r\n');

        return sdp;

        if (isMobileDevice) {
            return sdp;
        }
    
        params = params || {};
        var xgoogle_min_bitrate = params.min;
        var xgoogle_max_bitrate = params.max;
        var xgoogle_start_bitrate = params.start || xgoogle_min_bitrate;
        
        var codec = '';
        
        if(media == 'video') {
            var codec = 'VP8/90000';
            var codec_rtx = 'rtx/90000';
        
        }else if(media == 'audio') {
            var codec = 'opus/48000';
            var codec_rtx = 'red/48000';
        }

        if(!codec) return sdp;

        var sdp_lines = sdp.split('\r\n');
    
        var codec_index = findLine(sdp_lines, 'a=rtpmap', codec);
        var codec_payload;
        if (codec_index) {
            codec_payload = getCodecPayloadType(sdp_lines[codec_index]);
        }
        
        // intended codec not found so return
        if (!codec_payload) {
            return sdp;
        }
    
        var rtx_index = findLine(sdp_lines, 'a=rtpmap', codec_rtx);
        var rtx_payload;
        if (rtx_index) {
            rtx_payload = getCodecPayloadType(sdp_lines[rtx_index]);
        }

        var fmtp_line_index = false;
        var fmtp_line_found = false;

        sdp_lines.forEach((line, index) => {
            if(line.indexOf('a=fmtp:' + codec_payload) !== -1) {
                fmtp_line_index = index;
                fmtp_line_found = true;
                return;
            }
        });

        // no existing fmtp line found we will add new to this index
        if(!fmtp_line_index) {
            fmtp_line_index = rtx_index || codec_index;
        }

        var fmtp_line = sdp_lines[fmtp_line_index];

        // removing old bitrate params from line to avoid duplication, removing trivial colon and white space at end also
        fmtp_line = fmtp_line.replace(/x-google-max-bitrate=\d+;/g, '');
        fmtp_line = fmtp_line.replace(/x-google-min-bitrate=\d+;/g, '');
        fmtp_line = fmtp_line.replace(/x-google-start-bitrate=\d+;/g, '');
        fmtp_line = fmtp_line.replace(/x-google-max-bitrate=\d+/g, '');
        fmtp_line = fmtp_line.replace(/x-google-min-bitrate=\d+/g, '');
        fmtp_line = fmtp_line.replace(/x-google-start-bitrate=\d+/g, '');
        fmtp_line = fmtp_line.replace(/;\s*$/, "");
        fmtp_line = fmtp_line.replace(/ \s*$/, "");

        // need to add new fmtp line as it was not found
        if(!fmtp_line_found) {
            fmtp_line += '\r\n';
            fmtp_line += 'a=fmtp:' + codec_payload;
        }

        // adding colon before bitrate part if there is already some param with colon as this seems norm
        var bitrate_part = ' ';
        if(fmtp_line.indexOf(';') !== -1)
            bitrate_part = ';';

        // adding new bitrate params
        if(xgoogle_min_bitrate)
            bitrate_part += 'x-google-min-bitrate=' + xgoogle_min_bitrate + ';'
        if(xgoogle_start_bitrate)
            bitrate_part += 'x-google-start-bitrate=' + xgoogle_start_bitrate + ';';
        if(xgoogle_max_bitrate)
            bitrate_part += 'x-google-max-bitrate=' + xgoogle_max_bitrate + ';';

        // removing trivial last white space and colon
        bitrate_part = bitrate_part == ' ' ? '' : bitrate_part;
        bitrate_part = bitrate_part == ';' ? '' : bitrate_part;

        sdp_lines[fmtp_line_index] = fmtp_line + bitrate_part;

        return sdp_lines.join('\r\n');
    }

    function setVideoBitrates(sdp, params) {
        if (isMobileDevice) {
            return sdp;
        }

        params = params || {};
        var xgoogle_min_bitrate = params.min;
        var xgoogle_max_bitrate = params.max;
        var xgoogle_start_bitrate = params.start || xgoogle_min_bitrate;

        // need to remove the previous added params...
        // as it causes quality issues otherwise when any of the parameter is changed to different value than the previous
        sdp = sdp.replace(/\r\n/g, 'newline');
        sdp = sdp.replace(/x-google-max-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-min-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-start-bitrate=\d+;/g, '');
        sdp = sdp.replace(/x-google-max-bitrate=\d+/g, '');
        sdp = sdp.replace(/x-google-min-bitrate=\d+/g, '');
        sdp = sdp.replace(/x-google-start-bitrate=\d+/g, '');
        sdp = sdp.replace(/newline/g, '\r\n');

        var sdpLines = sdp.split('\r\n');

        // VP8
        var vp8Index = findLine(sdpLines, 'a=rtpmap', 'VP8/90000');
        var vp8Payload;
        if (vp8Index) {
            vp8Payload = getCodecPayloadType(sdpLines[vp8Index]);
        }

        if (!vp8Payload) {
            return sdp;
        }

        var rtxIndex = findLine(sdpLines, 'a=rtpmap', 'rtx/90000');
        var rtxPayload;
        if (rtxIndex) {
            rtxPayload = getCodecPayloadType(sdpLines[rtxIndex]);
        }

        if (!rtxIndex) {
            return sdp;
        }

        var rtxFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + rtxPayload.toString());
        if (rtxFmtpLineIndex !== null) {
            var appendrtxNext = '\r\n';
            appendrtxNext += 'a=fmtp:' + vp8Payload + ' ';

            if(xgoogle_min_bitrate)
                appendrtxNext += 'x-google-min-bitrate=' + xgoogle_min_bitrate + ';'

            if(xgoogle_start_bitrate)
                appendrtxNext += 'x-google-start-bitrate=' + xgoogle_start_bitrate + ';';
            
            if(xgoogle_max_bitrate)
                appendrtxNext += 'x-google-max-bitrate=' + xgoogle_max_bitrate + ';';
            
            sdpLines[rtxFmtpLineIndex] = sdpLines[rtxFmtpLineIndex].concat(appendrtxNext);
            sdp = sdpLines.join('\r\n');
        }

        return sdp;
    }

    function setOpusAttributes(sdp, params) {
        if (isMobileDevice) {
            return sdp;
        }

        params = params || {};

        var sdpLines = sdp.split('\r\n');

        // Opus
        var opusIndex = findLine(sdpLines, 'a=rtpmap', 'opus/48000');
        var opusPayload;
        if (opusIndex) {
            opusPayload = getCodecPayloadType(sdpLines[opusIndex]);
        }

        if (!opusPayload) {
            return sdp;
        }

        var opusFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + opusPayload.toString());
        if (opusFmtpLineIndex === null) {
            return sdp;
        }

        var appendOpusNext = '';
        appendOpusNext += '; stereo=' + (typeof params.stereo != 'undefined' ? params.stereo : '1');
        appendOpusNext += '; sprop-stereo=' + (typeof params['sprop-stereo'] != 'undefined' ? params['sprop-stereo'] : '1');

        if (typeof params.maxaveragebitrate != 'undefined') {
            appendOpusNext += '; maxaveragebitrate=' + (params.maxaveragebitrate || 128 * 1024 * 8);
        }

        if (typeof params.maxplaybackrate != 'undefined') {
            appendOpusNext += '; maxplaybackrate=' + (params.maxplaybackrate || 128 * 1024 * 8);
        }

        if (typeof params.cbr != 'undefined') {
            appendOpusNext += '; cbr=' + (typeof params.cbr != 'undefined' ? params.cbr : '1');
        }

        if (typeof params.useinbandfec != 'undefined') {
            appendOpusNext += '; useinbandfec=' + params.useinbandfec;
        }

        if (typeof params.usedtx != 'undefined') {
            appendOpusNext += '; usedtx=' + params.usedtx;
        }

        if (typeof params.maxptime != 'undefined') {
            appendOpusNext += '\r\na=maxptime:' + params.maxptime;
        }

        sdpLines[opusFmtpLineIndex] = sdpLines[opusFmtpLineIndex].concat(appendOpusNext);

        sdp = sdpLines.join('\r\n');
        return sdp;
    }

    // Find the line in sdpLines that starts with |prefix|, and, if specified,
    // contains |substr| (case-insensitive search).
    function findLine(sdpLines, prefix, substr) {
        return findLineInRange(sdpLines, 0, -1, prefix, substr);
    }

    // Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
    // and, if specified, contains |substr| (case-insensitive search).
    function findLineInRange(sdpLines, startLine, endLine, prefix, substr) {
        var realEndLine = endLine !== -1 ? endLine : sdpLines.length;
        for (var i = startLine; i < realEndLine; ++i) {
            if (sdpLines[i].indexOf(prefix) === 0) {
                if (!substr ||
                    sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                    return i;
                }
            }
        }
        return null;
    }

    // Gets the codec payload type from an a=rtpmap:X line.
    function getCodecPayloadType(sdpLine) {
        var pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
        var result = sdpLine.match(pattern);
        return (result && result.length === 2) ? result[1] : null;
    }

    return {
        setApplicationSpecificBandwidth: function(sdp, bandwidth) {
            return setApplicationSpecificBandwidth(sdp, bandwidth);
        },
        setVideoBitrates: function(sdp, params) {
            return setVideoBitrates(sdp, params);
        },
        setOpusAttributes: function(sdp, params) {
            return setOpusAttributes(sdp, params);
        },
        setBandwidth: function(sdp, bandwidth) {
            return setBandwidth(sdp, bandwidth);
        }
    };
});

export default BandwidthHandler