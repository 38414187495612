window.bus_events = window.bus_events || {};

const bus = {
	on(event, callback) {
		var total_events = Object.keys(window.bus_events).length;
		var event_number = Math.round(Math.random() * 100000000) + '_' + total_events;
		var event_callback = function (e) { callback(...e.detail) };

		window.bus_events[event_number] = {
			event,
			callback: event_callback
		}
		
		document.addEventListener(event, event_callback);

		return event_number;
	},
	dispatch(event, ...args) {
		document.dispatchEvent(new CustomEvent(event, { detail: args }));
	},
	remove(event_number) {
		// use window.bus_events[event_number] directly otherwise without saving to new variable
		// otherwise it will make new reference of callback function and removeEventListener will not work
		if(!window.bus_events[event_number]) return;

		document.removeEventListener(
			window.bus_events[event_number].event, 
			window.bus_events[event_number].callback
		);

		delete window.bus_events[event_number];
	}
};

window.bus = bus;

export default bus;